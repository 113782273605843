import { configureStore } from '@reduxjs/toolkit';
import { rootReducer, initialState } from './reducers';
import { createBrowserHistory } from 'history';
import { merge } from 'lodash';
import { AppState } from 'types';

export const history = createBrowserHistory();

localStorage.removeItem('tbl');
localStorage.removeItem('tablein');
const STORAGE_KEY = 'tablein-v3';

const saveToLocalStorage = (state: AppState) => {
  try {
    const serialisedState = JSON.stringify({
      app: state.app.app,
      preferences: state.app.preferences,
      config: state.app.config,
      rooms: state.app.rooms,
      tables: state.app.tables,
      settings: state.app.settings,
    });
    sessionStorage.setItem(STORAGE_KEY, serialisedState);
  } catch (e) {
    console.warn('Error saving state to localStorage:', e);
  }
};

const loadFromLocalStorage = (): Partial<AppState> => {
  try {
    const serialisedState = sessionStorage.getItem(STORAGE_KEY);
    return serialisedState ? JSON.parse(serialisedState) : {};
  } catch (e) {
    console.warn('Error loading state from localStorage:', e);
    return {};
  }
};

export const clearLocalStorage = () => {
  sessionStorage.removeItem(STORAGE_KEY);
};

const preloadedState = merge({}, initialState, { app: loadFromLocalStorage() });

// Store
const store = configureStore({
  reducer: rootReducer(),
  preloadedState,
  devTools: process.env.NODE_ENV === 'development',
});

store.subscribe(() => saveToLocalStorage(store.getState()));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;

export default store;
