import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { ReservationSection } from 'app/components/BookingModal/_components/ReservationSection';
import { GuestSection } from 'app/components/BookingModal/_components/GuestSection';
import { AvailableTablesSection } from 'app/components/BookingModal/_components/AvailableTablesSection';
import { BookingDialog } from 'app/components/BookingModal/_components/BookingDialog';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { BookingFormData } from 'types/app/booking';
import { useSnackbar } from 'notistack';
import { MessageVariants } from 'enums/notifications';
import { useTranslation } from 'react-i18next';
import reservationsActions from 'redux/actions/app/reservations';
import legacyBookingActions from 'redux/actions/app/booking';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getReservationValidationSchema } from 'app/components/BookingModal/_config';
import { BookingContentWrapper } from 'app/components/BookingModal/_components/BookingContentWrapper';
import { useTheme } from '@mui/material/styles';
import { PaymentConfirmationModal } from 'app/components/BookingModal/_components/PaymentConfirmationModal';
import { PaymentModal } from 'app/components/BookingModal/_components/PaymentModal';
import calendarActions from 'redux/actions/calendar';
import { getAmountDivider } from 'utils/number/getAmountDivider';
import { useBooking } from 'app/components/BookingModal/_hooks/useBooking';
import { actions as bookingActions } from 'redux/slices/bookingSlice';
import { bookingConditions, selectInitialFormValues } from 'redux/selectors/booking';
import { bookingInitialState } from 'redux/constants/app/bookingInitialState';
import { fetchBookingClient } from 'redux/slices/bookingSlice';
import { Statuses } from 'types/reservation';

export const NewBookingModal = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { view, isOpen, minAmount, reservation, client, isLoading, status, type, sub_status } =
    useAppSelector((state) => state.booking);
  const { showGuestDetails } = useAppSelector(bookingConditions);
  const initialValue = useAppSelector(selectInitialFormValues);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { refs, scrollToError } = useBooking();

  const phone_required = useAppSelector((state) => state.app.settings.reservation.phone_required);

  const lateCancellation = reservation?.special_offer?.late_cancellation;

  const isLateCancellation =
    reservation?.special_offer?.late_cancellation &&
    !Object.values(reservation?.special_offer?.late_cancellation).every((value) => value === null);

  const schema = yup.object(
    getReservationValidationSchema(
      minAmount,
      getAmountDivider(lateCancellation?.maximum_amount),
      255,
      lateCancellation?.currency,
    ),
  );

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: bookingInitialState,
    resolver: yupResolver(schema),
    context: { phone_required, isLateCancellation, showGuestDetails, status },
  });

  const {
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = methods;

  const onClose = () => {
    dispatch(bookingActions.closeModal());
    dispatch(bookingActions.reset());
  };

  const onPaymentModalClose = () => {
    setValue('late_cancellation', null);
    clearErrors(['late_cancellation.amount', 'late_cancellation.description']);

    dispatch(calendarActions.closeLateCancellationNoShowPaymentModal());
  };

  const isPaymentModalAvailable =
    isLateCancellation && (status === Statuses.NO_SHOW || status === Statuses.CANCELLED);

  const onSubmit = (data: BookingFormData) => {
    (async () => {
      const newData = {
        ...reservation,
        ...data,
        status,
        sub_status,
        type,
        lock_table: data.lock_table || false,
        payments: null,
        stripe_payment_data: null,
        reservation_date: null,
      };

      dispatch(bookingActions.closeModal());
      dispatch(bookingActions.reset());

      if (reservation) {
        try {
          if (isPaymentModalAvailable) {
            onPaymentModalClose();
          }

          await dispatch(
            legacyBookingActions.updateReservation(reservation.id, {
              ...newData,
            }),
          );
          if (isPaymentModalAvailable) {
            enqueueSnackbar(t('feeChargedSuccessfully'), { variant: MessageVariants.Success });
          }
          await dispatch(reservationsActions.getFullReservationsForDay());
        } catch (error) {
          enqueueSnackbar(t('updateReservationError'), { variant: MessageVariants.Error });
        }
      } else {
        try {
          await dispatch(legacyBookingActions.createReservation(newData));
          await dispatch(reservationsActions.getFullReservationsForDay());
        } catch (error) {
          enqueueSnackbar(t('createReservationError'), { variant: MessageVariants.Error });
        }
      }
    })();
  };

  useEffect(() => {
    reset(initialValue, { keepValues: false });
    if (reservation?.client?.id) {
      dispatch(fetchBookingClient(reservation.client.id));
    }
  }, [isOpen, reservation]);

  useEffect(() => {
    if (client) {
      setValue('client', client);
      setValue('client.tags', client.tags);
    }
  }, [client]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToError(errors, refs);
    }
  }, [errors]);

  return (
    <BookingDialog onClose={onClose} isOpen={isOpen} isLoading={isLoading}>
      <FormProvider {...methods}>
        <Grid
          component="form"
          id="booking-form"
          onSubmit={handleSubmit(onSubmit)}
          container
          spacing={1}
          sx={{
            padding: theme.customSpacing.bookingModal,
            width: '100%',
            height: '100%',
            margin: 0,
          }}
        >
          <BookingContentWrapper onClose={onClose}>
            <Grid item xs={12} md={8} hidden={view !== 'reservation'}>
              <ReservationSection guestsRef={refs.guests} tablesRef={refs.tables} />
            </Grid>
            <Grid item xs={12} md={4} hidden={view !== 'reservation'}>
              <GuestSection guestNameRef={refs.client.name} guestPhoneRef={refs.client.phone} />
            </Grid>
            <Grid item xs={12} hidden={!['tables', 'floor_plan'].includes(view)}>
              <AvailableTablesSection />
            </Grid>
          </BookingContentWrapper>
          <PaymentConfirmationModal lateCancellationTime={lateCancellation?.time} />
          <PaymentModal onPaymentModalClose={onPaymentModalClose} />
        </Grid>
      </FormProvider>
    </BookingDialog>
  );
};
