import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getConfig } from 'api/app/config';
import {
  Statuses,
  ReservationSubStatus,
  ReservationType as ReservationTypeEnum,
} from 'types/reservation';

export type Option = {
  value: string;
  label: string;
};

export type OptionGroup = {
  [key: string]: Option[];
};

export type NumberOption = {
  value: number;
  label: string;
};

export type ReservationType = {
  type: ReservationTypeEnum;
  statuses: Statuses[];
  sub_statuses?: ReservationSubStatus[];
};

export type ConfigState = {
  reservation_confirmations: Option[];
  reservation_tags: {
    additional_info: Option[];
    dietary_requirements: Option[];
  };
  reservation_durations: Option[];
  reservation_types: ReservationType[];
  client_tags: Option[];
  languages: Option[];
  system_languages: Option[];
  widget_languages: Option[];
  week_days: Option[];
  first_day_of_week: Option[];
  account_roles: Option[];
  account_permissions: OptionGroup;
  user_statuses: Option[];
  timezones: Option[];
  date_formats: Option[];
  user_restaurants: Option[];
  account_restaurants: Option[];
  account_waiters: Option[];
  countries: Option[];
  policies_and_terms: {
    [key: string]: Option[];
  };
  preset_permissions: {
    [key: string]: string[];
  };
  available_widget_languages: Option[];
  affiliate_restaurants: Option[];
  default_widget_language: string | null;
};

export const initialState: ConfigState = {
  reservation_confirmations: [],
  reservation_tags: {
    additional_info: [],
    dietary_requirements: [],
  },
  reservation_durations: [],
  reservation_types: [],
  client_tags: [],
  languages: [],
  system_languages: [],
  widget_languages: [],
  available_widget_languages: [],
  week_days: [],
  first_day_of_week: [],
  account_roles: [],
  account_permissions: {},
  user_statuses: [],
  timezones: [],
  date_formats: [],
  user_restaurants: [],
  account_restaurants: [],
  account_waiters: [],
  countries: [],
  policies_and_terms: {},
  affiliate_restaurants: [],
  preset_permissions: {},
  default_widget_language: null,
};

export const fetchConfig = createAsyncThunk('config/fetchConfig', async () => {
  return await getConfig();
});

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConfig.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

export const actions = configSlice.actions;
export const reducer = configSlice.reducer;
