import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';

type QueryParams = Record<string, string>;

export const getAdminSendMailLogsIndexCall = async (queryParams: QueryParams) => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ADMIN.LOGS.EMAIL,
      params: queryParams,
    },
    true,
  );
};

export const getAdminSendMailLogsShowCall = async (logId: number) => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.ADMIN.LOGS.EMAIL}/${logId}`,
    },
    true,
  );
};
