import { initialState } from 'redux/reducers/index';
import { AppActions, AppActionTypes, AppConfigState } from 'types/app/app';
import { unionBy } from 'lodash';

export const appConfigReducer = (
  state: AppConfigState = initialState.app.app,
  action: AppActions,
): AppConfigState => {
  switch (action.type) {
    case AppActionTypes.DisableSubmit:
      return {
        ...state,
        is_submit_available: false,
      };
    case AppActionTypes.EnableSubmit:
      return {
        ...state,
        is_submit_available: true,
      };
    case AppActionTypes.SubmitStartPending:
      return {
        ...state,
        is_submit_pending: true,
      };
    case AppActionTypes.SubmitEndPending:
      return {
        ...state,
        is_submit_pending: false,
      };
    case AppActionTypes.ChangeCurrentDate:
      return {
        ...state,
        ...action.payload,
      };
    case AppActionTypes.UpdateDisplayedDates:
      return {
        ...state,
        ...action.payload,
      };
    case AppActionTypes.UpdateOpeningHour:
      return {
        ...state,
        ...action.payload,
      };
    case AppActionTypes.UpdateOpeningHourBlockedTimes:
      return {
        ...state,
        opening_hour: {
          ...state.opening_hour,
          blocked_times: action.payload,
        },
      };
    case AppActionTypes.UpdateFilters:
      return {
        ...state,
        ...action.payload,
      };
    case AppActionTypes.ResetFilters:
      return {
        ...state,
        ...action.payload,
      };
    case AppActionTypes.UpdateSearch:
      return {
        ...state,
        ...action.payload,
      };
    case AppActionTypes.ResetSearch:
      return {
        ...state,
        ...action.payload,
      };
    case AppActionTypes.UpdateSearchTime:
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload,
        },
      };
    case AppActionTypes.UpdateSearchField:
      const { key, value } = action.payload;
      return {
        ...state,
        search: {
          ...state.search,
          [key]: value,
        },
      };
    case AppActionTypes.SetCurrentPage:
      return {
        ...state,
        searched_reservations: {
          ...state.searched_reservations,
          current_page: action.payload,
        },
      };
    case AppActionTypes.UpdateSearchedReservations:
      return {
        ...state,
        searched_reservations: {
          ...state.searched_reservations,
          ...action.payload,
        },
      };
    case AppActionTypes.ResetSearchedReservations:
      return {
        ...state,
        searched_reservations: action.payload,
      };
    case AppActionTypes.LoadMoreSearchedReservations:
      const { current_page, last_page, per_page, total, data } = action.payload;
      return {
        ...state,
        searched_reservations: {
          current_page,
          last_page,
          per_page,
          total,
          data: unionBy(state.searched_reservations.data, data, 'id'),
        },
      };
    default:
      return state;
  }
};
