import { SettingsState } from 'types/app/settings';
import { restaurantInitialState } from 'redux/constants/app/restaurantInitialState';
import { timeAndLanguageInitialState } from 'redux/constants/app/timeAndLanguageInitialState';
import { reservationInitialState } from 'redux/constants/app/reservationInitialState';
import { widgetSettingsInitialState } from 'redux/constants/app/widgetInitialState';
import { usersInitialState } from 'redux/constants/app/usersInitialState';
import { timeManagementInitialState } from 'redux/constants/app/timeManagementInitialState';
import { notificationSettingsInitialState } from 'redux/constants/app/notificationSettingsInitialState';
import { tableManagementInitialState } from 'redux/constants/app/tableManagementInitialState';

import { initialState as openingHoursInitialState } from 'redux/slices/openingHoursSlice';

export const settingsInitialState: SettingsState = {
  restaurant: restaurantInitialState,
  time_and_language: timeAndLanguageInitialState,
  users: usersInitialState,
  opening_hours: openingHoursInitialState,
  reservation: reservationInitialState,
  widget_settings: widgetSettingsInitialState,
  time_management: timeManagementInitialState,
  table_management: tableManagementInitialState,
  notification_settings: notificationSettingsInitialState,
};
