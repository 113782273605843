import { MutableRefObject, useMemo } from 'react';
import { BookingModalSection } from 'app/components/BookingModal/_components/BookingModalSection';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid } from '@mui/material';
import { NumbersOfGuests } from 'app/components/BookingModal/_components/ReservationSection/_components/NumbersOfGuests';
import { DateAndTime } from 'app/components/BookingModal/_components/ReservationSection/_components/DateAndTime';
import { AvailableTables } from 'app/components/BookingModal/_components/AvailableTables';
import { AvailableTablesViews, bookingModalConfig } from 'app/components/BookingModal/_config';
import { ReservationNote } from 'app/components/BookingModal/_components/ReservationNote';
import { bookingFormFieldNames } from 'app/components/BookingModal/_config';
import { useAppSelector } from 'redux/hooks';
import { DateControl, DateControlVariants } from 'app/components/DateControl';
import { calculatePaymentAmount } from 'utils/reservation/calculatePaymentAmount';
import { useStatus } from 'hooks/useStatus';
import { ShortBookingInfo } from 'app/components/BookingModal/_components/ReservationSection/_components/ShortReservationInfo';
import { StyledTextarea } from 'app/components/FormElements/StyledTextarea';
import { WidgetAdditionalFields } from 'app/components/BookingModal/_components/ReservationSection/_components/WidgetAdditionalFields';
import { ReservationTags } from 'app/components/BookingModal/_components/ReservationSection/_components/ReservationTags';
import { MuiSelect } from 'app/components/FormElements/MuiSelect';
import { CustomFileUpload } from 'app/components/CustomFileUpload';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
// import { NumberOfKids } from 'app/components/BookingModal/_components/ReservationSection/_components/NumberOfKids';
import { ReservationTagTypes } from 'types/reservation';
import { useScreenSize } from 'hooks/useScreenSize';
import { MuiTextarea } from 'app/components/FormElements/MuiTextarea';

interface OwnProps {
  guestsRef: MutableRefObject<any>;
  tablesRef: MutableRefObject<any>;
}

export const ReservationSection = ({ guestsRef, tablesRef }: OwnProps) => {
  const { reservation, type } = useAppSelector((state) => state.booking);
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const { watch } = useFormContext();

  const reservationTags = watch(bookingFormFieldNames.tags) || [];

  const successPayment = reservation?.payments?.find((payment) => payment.status === 'success');
  const paidAmount = successPayment && calculatePaymentAmount(successPayment);
  const isCardSaved = !!reservation?.stripe_payment_data?.id;

  const statuses = [];

  if (reservationTags.includes(ReservationTagTypes.Important)) {
    statuses.push({
      name: t('important'),
      status: 'vip',
    });
  }

  if (paidAmount) {
    statuses.push({
      name: t('paidAmount', { paidAmount }),
      status: 'paid',
    });
  }

  if (isCardSaved) {
    statuses.push({
      name: 'Card saved',
      status: 'card_saved',
    });
  }

  const { showReservationTags, showExperiences, showFilesAndExtraInformation, showComments } =
    bookingModalConfig(type);
  const specialOffers = useAppSelector((state) => state.app.special_offers);
  const { isReadOnly } = useStatus();
  const startTime = watch(bookingFormFieldNames.start_time);

  //TODO timestamp method
  const dateOnlyTimestamp = moment
    .utc(startTime * 1000)
    .startOf('day')
    .unix();

  const weekDayNumber = moment.unix(startTime).day();
  const filteredSpecialOffers = useMemo(() => {
    return specialOffers
      .filter(
        ({ date, valid_week }) =>
          dateOnlyTimestamp >= date.start &&
          dateOnlyTimestamp <= date.end &&
          valid_week.some(({ day_of_week, enabled }) => day_of_week === weekDayNumber && enabled),
      )
      .map(({ id, name }) => ({
        label: name,
        value: id,
      }));
  }, [specialOffers, dateOnlyTimestamp, weekDayNumber]);

  return (
    <BookingModalSection
      name={t('bookingModalReservationTitle')}
      statuses={statuses}
      additionalText={<ShortBookingInfo />}
      header={<ReservationNote />}
    >
      {!isMobile && (
        <Divider
          orientation="vertical"
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            height: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <DateControl variant={DateControlVariants.Modal} />
            <NumbersOfGuests guestsRef={guestsRef} />
            {/*<NumberOfKids />*/}
            <DateAndTime />
            <AvailableTables variant={AvailableTablesViews.Simplified} tablesRef={tablesRef} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {reservation?.client_comment && (
              <StyledTextarea
                label={t('guestRequestsBookedOnline')}
                value={reservation.client_comment}
                disabled
              />
            )}
            <WidgetAdditionalFields />
            {showComments && (
              <MuiTextarea name={bookingFormFieldNames.staff_comment} label={t('comment')} />
            )}
            {showReservationTags && <ReservationTags />}
            {showExperiences && (
              <MuiSelect
                disabled={isReadOnly}
                name={bookingFormFieldNames.special_offers}
                options={[
                  {
                    label: t('noSpecialOffer'),
                    value: null,
                  } as any,
                  ...filteredSpecialOffers,
                ]}
                label={t('reservationExperiencesLabel')}
                placeholder={t('pleaseSelect')}
              />
            )}
            {showFilesAndExtraInformation && (
              <CustomFileUpload multiple={true} name={bookingFormFieldNames.files} accept=".pdf" />
            )}
          </Box>
        </Grid>
      </Grid>
    </BookingModalSection>
  );
};
