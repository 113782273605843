import { CustomStyledCard } from 'app/pages/RestaurantSettings/_components/CustomStyledCard';
import { useTranslation } from 'react-i18next';
import { ReservationLengthTable } from 'app/pages/RestaurantSettings/TimeManagement/_components/ReservationLengthTable';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import { CustomBookingLength, TimeManagementRange } from 'types/app/timeManagement';
import {
  tableDefaultValues,
  timeManagementFieldNames,
} from 'app/pages/RestaurantSettings/TimeManagement/_config';
import { WeekdaySelector } from 'app/components/WeekdaySelector';
import { StartEndTimePickers } from 'app/components/StartEndTimePickers';
import { useAppDispatch } from 'redux/hooks';
import { MessageVariants } from 'enums/notifications';
import { useSnackbar } from 'notistack';
import timeManagementActions from 'redux/actions/app/timeManagement';
import { useState } from 'react';
import { ConfirmationModal } from 'app/components/Modals/ConfirmationModal';
import FormCardHeaderTitle from 'app/pages/RestaurantSettings/TimeManagement/_components/FormCard/_components/FormCardHeaderTitle';

interface OwnProps {
  id?: number;
  index?: number;
  bookingLength: CustomBookingLength;
  isCustom?: boolean;
  onDelete?: (data: CustomBookingLength) => void;
}

export const FormCard = ({ bookingLength, id, isCustom = false, onDelete }: OwnProps) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const defaultValues = bookingLength || tableDefaultValues;

  const methods = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const handleCustomBookingUpdate = (data: CustomBookingLength) => {
    const { name, valid_days, start_time, end_time, ranges } = data;

    dispatch(
      timeManagementActions.updateCustomBookingLengthById(
        {
          name,
          valid_days,
          start_time,
          end_time,
          ranges,
        },
        bookingLength.id,
      ),
    ).then(() => {
      enqueueSnackbar(t('updateCustomBookingTimeSuccess'), { variant: MessageVariants.Success });
    });
  };

  const handleDefaultBookingUpdate = (ranges: TimeManagementRange[]) => {
    dispatch(
      timeManagementActions.updateDefaultBookingLength({
        ranges,
      }),
    ).then(() => {
      enqueueSnackbar(t('updateTimeManagementSuccess'), { variant: MessageVariants.Success });
    });
  };

  const onSubmit = async (data: CustomBookingLength) => {
    const { ranges } = data;

    if (isCustom) {
      handleCustomBookingUpdate(data);
    } else {
      handleDefaultBookingUpdate(ranges);
    }

    reset(data);
    setIsInEditMode(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomStyledCard
            editMode={isInEditMode}
            title={
              <FormCardHeaderTitle
                isCustom={isCustom}
                isInEditMode={isInEditMode}
                bookingLength={bookingLength}
                onClick={() => setIsInEditMode(!isInEditMode)}
              />
            }
            additionalElements={
              <Box sx={{ display: 'flex', gap: '16px' }}>
                {isCustom && onDelete && id && (
                  <>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => setIsDeleteModalOpen(true)}
                    >
                      {t('delete')}
                    </Button>
                    <ConfirmationModal
                      open={isDeleteModalOpen}
                      onClose={() => setIsDeleteModalOpen(false)}
                      onClick={() => onDelete(bookingLength)}
                      title={t('deleteService')}
                      description={t('deleteServiceDescription')}
                      cancelButtonText={t('cancel')}
                      confirmButtonText={t('delete')}
                    />
                  </>
                )}
                <Button variant="contained" type="submit" disabled={!isDirty}>
                  {t('update')}
                </Button>
              </Box>
            }
          >
            {isCustom && (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                  <WeekdaySelector
                    name={timeManagementFieldNames.valid_days}
                    label={t('selectDays')}
                  />
                  <StartEndTimePickers
                    startName={timeManagementFieldNames.start_time}
                    startLabel={t('startTime')}
                    endName={timeManagementFieldNames.end_time}
                    endLabel={t('endTime')}
                    sx={{ maxWidth: '240px' }}
                  />
                </Box>
              </>
            )}
            <ReservationLengthTable />
          </CustomStyledCard>
        </form>
      </FormProvider>
    </>
  );
};
