import * as yup from 'yup';
import i18next from 'i18next';

const { t } = i18next;

const schema = yup.object().shape({
  min_guests: yup
    .number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .min(1, t('timeManagementMinGuests'))
    .max(998, t('timeManagementMaxGuests'))
    .required(t('thisFieldIsRequired')),
  max_guests: yup
    .number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .test('greater-than-min-guests', t('minGuestsGreaterThanMaxMessage'), function (value) {
      const { min_guests } = this.parent;
      return min_guests === null || value === null || value >= min_guests;
    })
    .min(1, t('timeManagementMinGuests'))
    .max(999, t('timeManagementMaxGuests'))
    .required(t('thisFieldIsRequired')),
  min_length: yup
    .number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .required(t('thisFieldIsRequired'))
    .test('less-than-max-length', t('minLengthGreaterThanMaxMessage'), function (value) {
      const { max_length } = this.parent;
      return max_length === undefined || value === undefined || value <= max_length;
    }),
  min_phone_length: yup
    .number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .required(t('thisFieldIsRequired'))
    .test('less-than-max-length', t('minLengthGreaterThanMaxMessage'), function (value) {
      const { max_length } = this.parent;
      return max_length === undefined || value === undefined || value <= max_length;
    }),
  max_length: yup.number().required(t('thisFieldIsRequired')),
});

export default schema;
