import {
  AppConfigState,
  IFilters,
  ISearchedReservations,
  ISearchReservations,
} from 'types/app/app';
import { createMomentUtc } from 'utils/date-time/createMomentUtc';
import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

export const filtersInitialState: IFilters = {
  rooms: [],
  reservation_tags: [],
  special_offers: [],
  reservation: [],
  statuses: [],
  guests: [],
  guests_options: [],
};

export const searchReservationsInitialState: ISearchReservations = {
  start_time: null,
  end_time: null,
  reservation_id: '',
  guest_name: '',
  guest_phone: '',
  guest_company: '',
  guest_email: '',
};

export const searchedReservationsInitialState: ISearchedReservations = {
  total: 0,
  per_page: 1,
  current_page: 1,
  last_page: 1,
  data: [],
};

export const appInitialState: AppConfigState = {
  current_date: createMomentUtc().startOf('day').unix(),
  displayed_dates: [],
  opening_hour: {
    date: {
      start: 0,
      end: 0,
    },
    time: {
      start: 0,
      end: 0,
      rounded_start: 0,
      rounded_end: 0,
    },
    blocked_times: [],
    is_closed: false,
  },
  filters: filtersInitialState,
  search: searchReservationsInitialState,
  searched_reservations: searchedReservationsInitialState,
  is_submit_available: true,
  is_submit_pending: false,
};
