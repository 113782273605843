import { ReservationSettingsState } from 'types/app/reservationSettings';

export const reservationInitialState: ReservationSettingsState = {
  phone_required: false,
  default_booking_length: 7200,
  auto_arrival: false,
  slot_guests: false,
  event_modal: false,
  no_show: false,
  walk_in: false,
  canceled: false,
  big_spender_tag: false,
  black_list_tag: false,
  original_time: false,
  preselected_notifications: false,
  interval: 0,
};
