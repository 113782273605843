import { Box } from '@mui/material';
import TableinLogo from 'images/icons/ic-tablein.svg';
import { ReactComponent as SettingsIcon } from 'images/icons/ic-settings.svg';
import { ReactComponent as CalendarIcon } from 'images/icons/ic-calendar.svg';
import { ReactComponent as ReportsIcon } from 'images/icons/ic-reports.svg';
import { ReactComponent as ListIcon } from 'images/icons/ic-list.svg';
import { ReactComponent as FloorPlanIcon } from 'images/icons/ic-floor-plan.svg';
import { ReactComponent as DatabaseIcon } from 'images/icons/ic-guestbook.svg';
import { ReactComponent as HomeIcon } from 'images/icons/ic-home-icon.svg';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { oldAppUrlGenerate } from 'utils/data-processors/url';
import { useAppSelector } from 'redux/hooks';
import useRouteMatcher from 'app/router/hooks/useRouteMatcher';
import paths from 'app/router/paths';
import SidebarNavigationItem from 'app/components/Navigation/NavigationWrapper/_components/SidebarNavigation/_components/SidebarNavigationItem';
import SidebarNavigationWrapper from 'app/components/Navigation/NavigationWrapper/_components/SidebarNavigation/_styles/SidebarNavigationWrapper';
import SidebarNavigationInner from 'app/components/Navigation/NavigationWrapper/_components/SidebarNavigation/_styles/SidebarNavigationInner';
import SidebarNavigationLinksWrapper from 'app/components/Navigation/NavigationWrapper/_components/SidebarNavigation/_styles/SidebarNavigationLinksWrapper';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SidebarDivider from 'app/components/Navigation/NavigationWrapper/_components/SidebarNavigation/_styles/SidebarDivider';
import { Guard } from 'app/components/Security/Guard';
import { Access } from 'hooks/useGuard';

const sidebarNavigationLinks = (t: TFunction) => [
  {
    to: paths.calendar.home,
    icon: <HomeIcon />,
    name: t('navigationHome'),
    hasAccess: true,
  },
  {
    to: paths.calendar.calendar,
    icon: <CalendarIcon />,
    name: t('navigationCalendar'),
    hasAccess: true,
  },
  {
    to: paths.calendar.reservationList,
    icon: <ListIcon />,
    name: t('navigationList'),
    hasAccess: true,
  },
  {
    to: oldAppUrlGenerate('floor-plan', false),
    icon: <FloorPlanIcon />,
    name: t('navigationFloorPlan'),
    hasAccess: true,
  },
  {
    to: oldAppUrlGenerate(paths.calendar.reports, false),
    icon: <ReportsIcon />,
    name: t('navigationReports'),
    // hasAccess: accessDashboard,
    hasAccess: true,
  },
  {
    to: oldAppUrlGenerate('settings/guests'),
    icon: <DatabaseIcon />,
    name: t('navigationDatabase'),
    hasAccess: true,
  },
  {
    to: paths.settings.accountSetup.restaurantInformation,
    icon: <SettingsIcon />,
    name: t('navigationSettings'),
    hasAccess: true,
  },
];

export const SidebarNavigation = () => {
  const { t } = useTranslation();
  const show_sidebars = useAppSelector((state) => state.app.preferences?.show_sidebars);
  const { routeIs } = useRouteMatcher();

  return (
    <SidebarNavigationWrapper
      isVisible={routeIs(paths.calendar.calendar) ? show_sidebars : true}
      className="no-print"
    >
      <Box
        component="img"
        src={TableinLogo}
        alt={t('tableinLogoAlt')}
        sx={{ width: '52px', height: '13px', marginTop: '12px' }}
        loading="lazy"
      />

      <SidebarNavigationInner>
        <SidebarNavigationLinksWrapper>
          {sidebarNavigationLinks(t)
            .slice(0, -3)
            .map((item) => (
              <SidebarNavigationItem key={item.name} {...item} />
            ))}
        </SidebarNavigationLinksWrapper>

        <SidebarNavigationLinksWrapper>
          {sidebarNavigationLinks(t)
            .slice(-3)
            .map((item) => (
              <SidebarNavigationItem key={item.name} {...item} />
            ))}

          <Guard access={Access.AdminSettings}>
            <SidebarDivider />
            <SidebarNavigationItem
              to={paths.admin.dashboard}
              name={'Admin'}
              icon={<AdminPanelSettingsIcon />}
              hasAccess={true}
            />
          </Guard>
        </SidebarNavigationLinksWrapper>
      </SidebarNavigationInner>
    </SidebarNavigationWrapper>
  );
};
