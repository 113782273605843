import { Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectReservationStatusOptions,
  selectReservationTypeStatuses,
} from 'redux/selectors/booking';
import React, { useEffect } from 'react';
import { actions as bookingActions } from 'redux/slices/bookingSlice';
import { Button } from 'app/components/BookingModal/_components/BookingTopBar/_components/TopBarButton';
import { CustomSelect } from 'app/components/FormElements/CustomSelect';
import { Statuses } from 'types/reservation';
import { shouldPayForLateCancellationNoShow } from 'utils/reservation/reservations';
import calendarActions from 'redux/actions/calendar';
import moment from 'moment-timezone';
import { useScreenSize } from 'hooks/useScreenSize';
import { useFormContext } from 'react-hook-form';
import { getStatusColor } from 'utils/data-processors/getStatusColors';

export const StatusButtons = () => {
  const options = useAppSelector(selectReservationStatusOptions);
  const typeStatuses = useAppSelector(selectReservationTypeStatuses);
  const { reservation, status, type } = useAppSelector((state) => state.booking);
  const dispatch = useAppDispatch();
  const settings = useAppSelector((state) => state.app.settings);
  const now = moment().utc().valueOf() + settings.time_and_language.time_zone_offset * 60 * 1000;
  const { isMobile } = useScreenSize();
  const { watch } = useFormContext();
  const startTime = watch('start_time');

  useEffect(() => {
    if (!typeStatuses?.includes(status)) {
      dispatch(bookingActions.setStatus(options[0].value));
    }
  }, [type]);

  const checkAndSetBookingStatus = (bookingStatus: Statuses) => {
    dispatch(bookingActions.setStatus(bookingStatus));

    const showLcfModal = shouldPayForLateCancellationNoShow({
      now,
      bookingStatus,
      start_time: startTime,
      late_cancellation: reservation?.special_offer?.late_cancellation,
      stripe_payment_data: reservation?.stripe_payment_data,
    });

    if (showLcfModal) {
      dispatch(calendarActions.openLateCancellationNoShowPaymentConfirmationModal());
    }
  };

  if (options.length <= 1) {
    return null;
  }

  if (isMobile) {
    return (
      <CustomSelect
        options={options}
        value={status}
        onChange={(event) => checkAndSetBookingStatus(event.target.value as Statuses)}
        sx={{
          maxWidth: 'fit-content',
        }}
      />
    );
  }

  return (
    <Stack maxWidth={'1180px'} flexDirection={'row'} flexWrap={'wrap'} gap={1}>
      {options.map((option) => {
        const colors = getStatusColor(option.value);
        return (
          <Button
            key={option.value}
            value={option.value}
            label={option.label}
            selectedStatus={status}
            onClick={(value) => checkAndSetBookingStatus(value as Statuses)}
            primaryColor={colors.firstColor}
            secondaryColor={colors.secondColor}
          />
        );
      })}
    </Stack>
  );
};
