import { initialState } from './index';
import { CalendarAction, CalendarState } from 'types/calendar';

export function calendarReducer(
  state: CalendarState = initialState.calendar,
  action: CalendarAction,
): CalendarState {
  switch (action.type) {
    case 'Calendar.LocaleSet':
      return {
        ...state,
        locale: action.payload.locale,
      };

    case 'Calendar.LateCancellationNoShow.PaymentConfirmationModalClose':
      return {
        ...state,
        lateCancellationNoShowPayment: {
          ...state.lateCancellationNoShowPayment,
          askForPaymentModalIsOpen: action.payload.askForPaymentModalIsOpen,
        },
      };
    case 'Calendar.LateCancellationNoShow.PaymentConfirmationModalOpen':
      return {
        ...state,
        lateCancellationNoShowPayment: {
          ...state.lateCancellationNoShowPayment,
          askForPaymentModalIsOpen: action.payload.askForPaymentModalIsOpen,
        },
      };
    case 'Calendar.LateCancellationNoShow.PaymentModalOpen':
      return {
        ...state,
        lateCancellationNoShowPayment: {
          ...state.lateCancellationNoShowPayment,
          paymentModalIsOpen: action.payload.paymentModalIsOpen,
        },
      };
    case 'Calendar.LateCancellationNoShow.PaymentModalClose':
      return {
        ...state,
        lateCancellationNoShowPayment: {
          ...state.lateCancellationNoShowPayment,
          paymentModalIsOpen: action.payload.paymentModalIsOpen,
        },
      };
    default:
      return state;
  }
}
