import { ReactNode, useEffect, useState } from 'react';
import { actions as applicationActions } from 'redux/slices/applicationSlice';
import { useAppDispatch } from 'redux/hooks';

type Props = {
  children: ReactNode;
};

const ConnectionListenerWrapper = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);
    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  });

  useEffect(() => {
    dispatch(
      applicationActions.updateConnectionStatus({
        isOffline: !isOnline,
        isServerAvailable: isOnline,
      }),
    );
    // eslint-disable-next-line
  }, [isOnline]);

  return <>{children}</>;
};

export default ConnectionListenerWrapper;
