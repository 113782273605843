import { ReservationSubStatus, ReservationType, Statuses } from 'types/reservation';

export interface IReservationColors {
  firstColor: string;
  secondColor: string;
}

export const getStatusColors = (
  status: Statuses,
  isPastReservation: boolean,
): IReservationColors => {
  const colors = {
    [Statuses.SEATED]: { firstColor: 'accent.green', secondColor: 'green1' },
    [Statuses.PARTLY_SEATED]: { firstColor: 'accent.green', secondColor: 'green1' },
    [Statuses.BLOCKED]: { firstColor: 'accent.red', secondColor: 'pigPink' },
    [Statuses.CANCELLED]: { firstColor: 'accent.gray', secondColor: 'gray16' },
    [Statuses.CONFIRMED]: isPastReservation
      ? { firstColor: 'accent.green', secondColor: 'green1' }
      : { firstColor: 'accent.blue', secondColor: 'blue1' },
    [Statuses.NO_SHOW]: { firstColor: 'accent.red', secondColor: 'pigPink' },
    [Statuses.BEING_LATE]: { firstColor: 'accent.blue', secondColor: 'blue1' },
    [Statuses.PENDING]: { firstColor: 'accent.yellow', secondColor: 'yellow11' },
    [Statuses.TEMPLATE]: { firstColor: 'accent.gray', secondColor: 'gray16' },
    [Statuses.WALK_IN]: { firstColor: 'accent.violet', secondColor: 'violet1' },
    [Statuses.WAITING_LIST]: { firstColor: 'accent.brown', secondColor: 'brown2' },
    [Statuses.NONE]: { firstColor: 'accent.gray', secondColor: 'gray16' },
    [Statuses.PRE_BOOKED]: { firstColor: 'accent.yellow', secondColor: 'yellow11' },
  };
  return colors[status] || { firstColor: 'accent.blue', secondColor: 'blue1' };
};

export const getTypeColor = (type: ReservationType) => {
  switch (type) {
    case ReservationType.BOOKING:
      return { firstColor: 'accent.blue', secondColor: 'blue1' };
    case ReservationType.WALK_IN:
      return { firstColor: 'accent.violet', secondColor: 'violet1' };
    case ReservationType.EVENT:
      return { firstColor: 'accent.yellow', secondColor: 'yellow11' };
    case ReservationType.BLOCKED:
      return { firstColor: 'accent.red', secondColor: 'pigPink' };
    case ReservationType.WAITING_LIST:
      return { firstColor: 'accent.brown', secondColor: 'brown2' };
    case ReservationType.TEMPLATE:
      return { firstColor: 'accent.gray', secondColor: 'gray16' };
    default:
      return { firstColor: 'accent.blue', secondColor: 'blue1' };
  }
};

export const getStatusColor = (status: Statuses | ReservationSubStatus): IReservationColors => {
  switch (status) {
    case Statuses.CONFIRMED:
      return { firstColor: 'accent.blue', secondColor: 'blue1' };
    case Statuses.SEATED:
    case Statuses.PARTLY_SEATED:
      return { firstColor: 'accent.green', secondColor: 'green1' };
    case Statuses.PENDING:
    case Statuses.PRE_BOOKED:
      return { firstColor: 'accent.yellow', secondColor: 'yellow11' };
    case Statuses.NO_SHOW:
      return { firstColor: 'accent.red', secondColor: 'pigPink' };
    case Statuses.CANCELLED:
      return { firstColor: 'accent.gray', secondColor: 'gray16' };
    default:
      return { firstColor: 'accent.blue', secondColor: 'blue1' };
  }
};
