import { ButtonBase, Typography, useTheme } from '@mui/material';

type Props = {
  value: string;
  label: string;
  selectedStatus: string;
  onClick: (value: string) => void;
  primaryColor: string;
  secondaryColor: string;
  disabled?: boolean;
};

export const Button = ({
  value,
  label,
  selectedStatus,
  onClick,
  primaryColor,
  secondaryColor,
  disabled = false,
}: Props) => {
  const theme = useTheme();

  return (
    <ButtonBase
      disabled={disabled}
      onClick={() => onClick(value)}
      sx={{
        minHeight: '40px',
        borderRadius: theme.borderRadius.small,
        padding: '4px 8px',
        backgroundColor: value === selectedStatus ? secondaryColor : 'initial',
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          lineHeight: 1,
          color: value === selectedStatus ? primaryColor : 'comet',
        }}
      >
        {label}
      </Typography>
    </ButtonBase>
  );
};
