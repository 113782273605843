import { Box } from '@mui/material';
import { TagSelector } from 'app/components/FormElements/TagSelector';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';
import { bookingFormFieldNames } from 'app/components/BookingModal/_config';

export const ReservationTags = () => {
  const { reservation_tags } = useAppSelector((state) => state.app.config);
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <TagSelector
        name={bookingFormFieldNames.tags}
        label={t('reservationTagsAdditionalInfoTitle')}
        tags={reservation_tags.additional_info}
        rolledWrapperSx={{
          paddingBottom: 0,
        }}
        tagsWrapperSx={{
          maxWidth: '536px',
        }}
      />
      <TagSelector
        name={bookingFormFieldNames.tags}
        label={t('reservationTagsDietaryRequirementsTitle')}
        tags={reservation_tags.dietary_requirements}
        rolledWrapperSx={{
          paddingBottom: 0,
        }}
        tagsWrapperSx={{
          maxWidth: '536px',
        }}
      />
    </Box>
  );
};
