import { combineReducers } from 'redux';
import { AppState } from 'types';
import { calendarReducer } from './calendar';
import { authReducer } from './auth';
import { notesReducer } from './notes';
import { systemReducer } from './system';
import { themeReducer } from './theme';
import { restaurantReducer } from 'redux/reducers/app/restaurant';
import { authInitialState } from 'redux/constants/authInitialState';
import { systemInitialState } from 'redux/constants/systemInitialState';
import { calendarInitialState } from 'redux/constants/calendarInitialState';
import { notesInitialState } from 'redux/constants/notesInitialState';
import { themeInitialState } from 'redux/constants/themeInitialState';
import { settingsInitialState } from 'redux/constants/app/settingsInitialState';
import { timeAndLanguageReducer } from 'redux/reducers/app/timeAndLanguage';
import { appInitialState } from 'redux/constants/app/appInitialState';
import { appConfigReducer } from 'redux/reducers/app/app';
import { reservationSettingsReducer } from 'redux/reducers/app/reservationSettings';
import { bookingInitialState as legacyBookingInitialState } from 'redux/constants/app/bookingInitialState';
import { bookingReducer as legacyBookingReducer } from 'redux/reducers/app/booking';
import { reservationsInitialState } from 'redux/constants/app/reservationsInitialState';
import { reservationsReducer } from 'redux/reducers/app/reservations';
import { usersReducer } from 'redux/reducers/app/users';
import { profileReducer } from 'redux/reducers/app/profile';
import { widgetSettingsReducer } from 'redux/reducers/app/widgetSettings';
import { profileInitialState } from 'redux/constants/app/profileInitialState';
import { roomsInitialState } from 'redux/constants/app/roomsInitialState';
import { tablesInitialState } from 'redux/constants/app/tablesInitialState';
import { roomsReducer } from 'redux/reducers/app/rooms';
import { tablesReducer } from 'redux/reducers/app/tables';
import { specialOffersInitialState } from 'redux/constants/app/specialOffersInitialState';
import { specialOffersReducer } from 'redux/reducers/app/specialOffers';
import { loadingInitialState } from 'redux/constants/app/loadingInitialState';
import { loadingReducer } from 'redux/reducers/app/loading';
import { preferencesInitialState } from 'redux/constants/app/preferencesInitialState';
import { preferencesReducer } from 'redux/reducers/app/preferences';
import { timeManagementReducer } from 'redux/reducers/app/timeManagement';
import { notificationSettingsReducer } from 'redux/reducers/app/notificationSettings';
import { tableManagementReducer } from 'redux/reducers/app/tableManagement';
import { AvailableSlotsState } from 'redux/constants/app/availableSlotsState';
import { AvailableSlotsReducer } from 'redux/reducers/app/availableSlots';
import {
  initialState as bookingInitialState,
  reducer as bookingReducer,
} from 'redux/slices/bookingSlice';
import {
  initialState as configInitialState,
  reducer as configReducer,
} from 'redux/slices/configSlice';

import {
  initialState as clientsInitialState,
  reducer as clientsReducer,
} from 'redux/slices/clientsSlice';

import {
  initialState as applicationInitialState,
  reducer as applicationReducer,
} from 'redux/slices/applicationSlice';

import { reducer as openingHoursReducer } from 'redux/slices/openingHoursSlice';

export const tableinAppInitialState = {
  app: appInitialState,
  preferences: preferencesInitialState,
  profile: profileInitialState,
  booking: legacyBookingInitialState,
  rooms: roomsInitialState,
  tables: tablesInitialState,
  reservations: reservationsInitialState,
  special_offers: specialOffersInitialState,
  settings: settingsInitialState,
  loading: loadingInitialState,
  available_slots: AvailableSlotsState,
  config: configInitialState,
};

export const initialState: AppState = {
  auth: authInitialState,
  application: applicationInitialState,
  system: systemInitialState,
  calendar: calendarInitialState,
  notes: notesInitialState,
  theme: themeInitialState,
  app: tableinAppInitialState,
  booking: bookingInitialState,
  clients: clientsInitialState,
};

const settingsReducer = combineReducers({
  restaurant: restaurantReducer,
  time_and_language: timeAndLanguageReducer,
  users: usersReducer,
  opening_hours: openingHoursReducer,
  reservation: reservationSettingsReducer,
  widget_settings: widgetSettingsReducer,
  time_management: timeManagementReducer,
  table_management: tableManagementReducer,
  notification_settings: notificationSettingsReducer,
});

const appReducer = combineReducers({
  app: appConfigReducer,
  preferences: preferencesReducer,
  profile: profileReducer,
  booking: legacyBookingReducer,
  rooms: roomsReducer,
  tables: tablesReducer,
  reservations: reservationsReducer,
  special_offers: specialOffersReducer,
  settings: settingsReducer,
  loading: loadingReducer,
  available_slots: AvailableSlotsReducer,
  config: configReducer,
});

export const rootReducer = () =>
  combineReducers({
    auth: authReducer,
    application: applicationReducer,
    system: systemReducer,
    calendar: calendarReducer,
    notes: notesReducer,
    theme: themeReducer,
    app: appReducer,
    booking: bookingReducer,
    clients: clientsReducer,
  });
