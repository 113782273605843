import { baseUrl, asyncRequest } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { ConfigState } from 'redux/slices/configSlice';

export const getConfig = async (): Promise<ConfigState> =>
  await asyncRequest({
    method: 'GET',
    baseURL: baseUrl,
    url: ENDPOINT_URLS.CONFIG.ROOT,
  });
