import { IComment } from 'types/app/reservations';
import {
  IReservationClient,
  IReservationData,
  IReservationNotifications,
} from 'types/app/reservations';

export enum BookingActionTypes {
  UpdateBooking = 'Booking.UpdateBooking',
  ResetBooking = 'Booking.ResetBooking',
  SetReservationComment = 'Booking.SetReservationComment',
  DeleteReservationComment = 'Booking.DeleteReservationComment',
  UpdateReservationClient = 'Booking.UpdateReservationClient',
}

interface IBookingLateCancellation {
  currency: string;
  amount: number | null;
  description: string | null;
}

export interface BookingFormData extends IReservationData {
  date?: number;
  tags: string[];
  notifications: IReservationNotifications;
  client: IReservationClient;
  late_cancellation: IBookingLateCancellation | null;
}

export type BookingAction =
  | {
      type: BookingActionTypes.UpdateBooking | BookingActionTypes.ResetBooking;
      payload: BookingFormData;
    }
  | { type: BookingActionTypes.SetReservationComment; payload: IComment }
  | { type: BookingActionTypes.DeleteReservationComment; payload: number }
  | { type: BookingActionTypes.UpdateReservationClient; payload: IReservationClient };
