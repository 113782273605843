import { ReactNode } from 'react';
import { Box, Divider, Stack, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledLabel } from 'app/components/BookingModal/_components/StyledLabel';
import { MainHeading } from 'app/components/TextElements/MainHeading';
import { BlurCurtain } from 'app/components/BookingModal/_components/BlurCurtain';
import { useScreenSize } from 'hooks/useScreenSize';

type Status = {
  [key: string]: string;
};

interface OwnProps {
  name?: string;
  children: ReactNode;
  header?: ReactNode;
  sx?: SxProps;
  statuses?: Status[];
  additionalText?: ReactNode;
  sectionDisabled?: boolean;
}

export const BookingModalSection = ({
  name,
  children,
  header,
  additionalText,
  statuses,
  sectionDisabled = false,
}: OwnProps) => {
  const theme = useTheme();
  const { isMobile } = useScreenSize();

  if (isMobile && sectionDisabled) {
    return null;
  }

  return (
    <Box
      sx={{
        borderRadius: theme.borderRadius.small,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'brandWhite',
        height: '100%',
        width: '100%',
        position: 'relative',
        zIndex: 4,
      }}
    >
      {sectionDisabled && <BlurCurtain />}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '12px',
        }}
      >
        <Stack alignItems="center" gap={2} direction={'row'}>
          <MainHeading
            sx={{
              wordBreak: 'break-word',
            }}
          >
            {name}
          </MainHeading>
          <Box>{additionalText}</Box>
        </Stack>
        <Stack alignItems="center" gap={1} direction={'row'}>
          {statuses?.map(({ name, status }) => (
            <StyledLabel name={name} key={status} status={status} />
          ))}
        </Stack>
      </Box>
      <Box>{header}</Box>
      <Divider />
      <Box
        sx={{
          padding: '10px 12px 12px 12px',
          height: '100%',
          position: 'relative',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
