import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const StyledGuestButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isReadOnly',
})<{ isSelected: boolean; isReadOnly: boolean }>(({ theme, isSelected, isReadOnly }) => ({
  minWidth: '40px',
  height: '40px',
  padding: 1,
  border: 0,
  color: isSelected ? 'white' : theme.palette.text.primary,
  backgroundColor: isSelected ? theme.palette.secondary.main : theme.palette.zircon,
  '&:hover': {
    backgroundColor: isSelected ? theme.palette.secondary.main : theme.palette.action.hover,
  },
  '&.Mui-disabled': {
    borderColor: 'transparent',
    opacity: isReadOnly ? 0.5 : 1,
  },
}));
