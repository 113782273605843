import { baseUrl, asyncRequest } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { RestaurantInfo, RestaurantSocialMedia, PlaceType } from 'types/app/restaurant';

interface SignatureDish {
  label: string;
  color: string;
}

interface Description {
  lang?: string;
  body?: string;
}

interface Profile {
  drink_menu?: string;
  food_menu?: string;
  show_ratings?: boolean;
  show_open_hours?: boolean;
  signature_dishes?: SignatureDish[];
  description?: Description[];
}

export interface RestaurantData {
  info?: RestaurantInfo;
  address?: PlaceType;
  social_media?: RestaurantSocialMedia;
  profile?: Profile;
}

export enum RestaurantPopulateKeys {
  Info = 'info',
  Address = 'address',
  SocialMedia = 'social_media',
  Profile = 'profile',
}

export const updateRestaurantCall = async (data: RestaurantData) => {
  return await asyncRequest({
    method: 'PUT',
    data,
    baseURL: baseUrl,
    url: ENDPOINT_URLS.RESTAURANT.ROOT,
  });
};

export const getRestaurantCall = async (populateFields: string | null = null) => {
  const params: Record<string, string> = {};
  if (populateFields) {
    params.populate = populateFields;
  }

  return await asyncRequest({
    method: 'GET',
    baseURL: baseUrl,
    url: ENDPOINT_URLS.RESTAURANT.ROOT,
    params,
  });
};
