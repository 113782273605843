import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { PaginatedResponse } from 'types/app/paginatedResponse';
import { RestaurantAdminList } from 'types/admin/Restaurant';

type QueryParams = Record<string, string>;

export const getAdminRestaurantsIndexCall = async (
  queryParams: QueryParams,
): Promise<PaginatedResponse<RestaurantAdminList[]>> => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ADMIN.RESTAURANT.ROOT,
      params: queryParams,
    },
    true,
  );
};
