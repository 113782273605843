import paths from 'app/router/paths';
import { lazy } from 'react';
import {
  getAdminSendMailLogsIndexCall,
  getAdminSendMailLogsShowCall,
} from 'api/app/admin/logs/email';
import { LoaderFunctionArgs } from 'react-router-dom';
import { queryStringToObject } from 'utils/http/queryStringToObject';
import { getAdminErrorLogsCall } from 'api/app/admin/logs/error';
import { getAdminRestaurantsIndexCall } from 'api/app/admin/restaurant';
import { getAdminReservationsIndexCall } from 'api/app/admin/reservation';

const ReservationListPage = lazy(() => import('app/pages/Admin/Reservation/ReservationList'));

const adminReservations = [
  {
    path: paths.admin.reservation.index,
    element: <ReservationListPage />,
    loader: (loader: LoaderFunctionArgs) => {
      const searchQuery = queryStringToObject(loader.request.url);

      return getAdminReservationsIndexCall(searchQuery);
    },
  },
];

export default adminReservations;
