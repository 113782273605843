import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IReservation } from 'types/app/reservations';
import { BookingFormData } from 'types/app/booking';
import { bookingInitialState } from 'redux/constants/app/bookingInitialState';

import { getReservationCall } from 'api/app/restaurant/reservation';
import { RootState } from 'redux/store';
import { Statuses, ReservationSubStatus, ReservationType } from 'types/reservation';
import { getClientCall } from 'api/app/client';
import { Client } from 'types/app/clients';

export type BookingView = 'reservation' | 'tables' | 'floor_plan';

export type BookingState = {
  isOpen: boolean;
  isLoading: boolean;
  isNew: boolean;
  view: BookingView;
  minAmount: number;
  status: Statuses;
  sub_status?: ReservationSubStatus;
  type: ReservationType;
  initialValues: BookingFormData;
  reservation?: IReservation;
  client?: Client;
};

type InitialValues = {
  start_time: number;
  end_time: number;
  tables: string[];
};

export const initialState: BookingState = {
  isOpen: false,
  isLoading: false,
  isNew: true,
  view: 'reservation',
  minAmount: 0.5,
  status: Statuses.CONFIRMED,
  type: ReservationType.BOOKING,
  initialValues: bookingInitialState,
  reservation: undefined,
  client: undefined,
};
export const fetchReservation = createAsyncThunk(
  'booking/fetch.reservation',
  async (id: number, { dispatch, getState }): Promise<IReservation> => {
    const response = await getReservationCall(id);

    const state = getState() as RootState;
    const booking = state.booking;

    if (booking.reservation?.updated_at !== response.updated_at && booking.isOpen) {
      dispatch(bookingSlice.actions.setReservation(response));
    }

    return response;
  },
);

export const fetchBookingClient = createAsyncThunk('booking/fetchClient', async (id: number) => {
  return await getClientCall(id);
});

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    openModal: (state) => {
      state.isOpen = true;
    },
    setInitialValues: (state, action: PayloadAction<InitialValues>) => {
      state.initialValues.start_time = action.payload.start_time;
      state.initialValues.end_time = action.payload.end_time;
      state.initialValues.tables = action.payload.tables;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setView: (state, action: PayloadAction<BookingView>) => {
      state.view = action.payload;
    },
    setStatus: (state, action: PayloadAction<Statuses>) => {
      state.status = action.payload;
    },
    setSubStatus: (state, action: PayloadAction<ReservationSubStatus | undefined>) => {
      state.sub_status = action.payload;
    },
    setType: (state, action: PayloadAction<ReservationType>) => {
      state.type = action.payload;
    },
    setReservation: (state, action: PayloadAction<IReservation>) => {
      state.isNew = false;
      state.status = action.payload.status;
      state.sub_status = action.payload.sub_status;
      state.type = action.payload.type;
      state.reservation = action.payload;
      state.client = action.payload.client;

      state.initialValues.start_time = action.payload.start_time;
      state.initialValues.end_time = action.payload.end_time;
      state.initialValues.staff_comment = action.payload.staff_comment;
      state.initialValues.guests = action.payload.guests;
      state.initialValues.kids = action.payload.kids;
      state.initialValues.tables = action.payload.tables.map((table) => table.toString());
      state.initialValues.tags = action.payload.tags || [];
      state.initialValues.special_offer = action.payload.special_offer;
      state.initialValues.files = action.payload.files;
      state.initialValues.lock_table = action.payload.lock_table;
      state.initialValues.waiter_id = action.payload.waiter_id;

      state.initialValues.client = {
        ...action.payload.client,
      };
    },
    setClient: (state, action: PayloadAction<Client>) => {
      state.client = action.payload;
      state.initialValues.client = {
        ...action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReservation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchReservation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reservation = action.payload;
    });
    builder.addCase(fetchReservation.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchBookingClient.fulfilled, (state, action) => {
      state.client = action.payload;
    });
  },
});

export const { openModal, closeModal, setLoading, setView, setStatus } = bookingSlice.actions;

export const actions = bookingSlice.actions;

export const reducer = bookingSlice.reducer;
