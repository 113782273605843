import { MutableRefObject, useEffect, useState } from 'react';
import { Heading } from 'app/components/Heading';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { CustomNarrowButton } from 'app/components/Buttons/CustomNarrowButton';
import { ReactComponent as LockIcon } from 'images/icons/ic-lock.svg';
import { ReactComponent as ArrowIcon } from 'images/icons/ic-arrow-narrow-right.svg';
import { useController, useFormContext } from 'react-hook-form';
import { AvailableTablesViews, bookingFormFieldNames } from 'app/components/BookingModal/_config';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { CustomErrorMessage } from 'app/components/CustomErrorMessage';
import { selectGroupedTables } from 'redux/selectors/tables';
import { MuiCheckbox } from 'app/components/FormElements/MuiCheckbox';
import { IReservation } from 'types/app/reservations';
import { selectCurrentOpeningHours } from 'redux/selectors/openingHours';
import { AppActionTypes } from 'types/app/app';
import { TableButton } from 'app/components/BookingModal/_components/TableButton';
import { StyledTypography } from 'app/components/TextElements/StyledTypography';
import { useStatus } from 'hooks/useStatus';
import { ITable } from 'types/app/tables';
import { generateFlexBasisStyles } from 'utils/style/generateFlexBasisStyles';
import { useScreenSize } from 'hooks/useScreenSize';
import { actions as bookingActions } from 'redux/slices/bookingSlice';
import { Statuses } from 'types/reservation';

interface OwnProps {
  variant: AvailableTablesViews;
  tablesRef?: MutableRefObject<any>;
}

export const AvailableTables = ({ variant, tablesRef }: OwnProps) => {
  const { t } = useTranslation();
  const { view } = useAppSelector((state) => state.booking);
  const dispatch = useAppDispatch();
  const { time } = useAppSelector(selectCurrentOpeningHours);
  const { reservation, status, type } = useAppSelector((state) => state.booking);
  const groupedTables = useAppSelector(selectGroupedTables);
  const reservations = useAppSelector((state) => state.app.reservations);
  const isSubmitAvailable = useAppSelector((state) => state.app.app.is_submit_available);

  const {
    control,
    setValue,
    getValues,
    watch,
    setError,
    formState: { errors },
  } = useFormContext();

  const { isReadOnly } = useStatus();
  const { isMax900 } = useScreenSize();
  const isLocked = watch(bookingFormFieldNames.lock_table);

  const [overlappingTablesArray, setOverlappingTablesArray] = useState<(number | string)[]>([]);

  const name = bookingFormFieldNames.tables;

  const startTime = watch(bookingFormFieldNames.start_time);
  const endTime = watch(bookingFormFieldNames.end_time);
  const selectedTables = getValues(name);
  const reservationId = reservation?.id;

  const isSimplified = variant === AvailableTablesViews.Simplified;
  const isFull = variant === AvailableTablesViews.Full;

  const {
    field: { value },
  } = useController({
    name,
    control,
  });

  useEffect(() => {
    const excludedStatuses = [Statuses.CANCELLED, Statuses.NO_SHOW];

    if (excludedStatuses.includes(status)) {
      setOverlappingTablesArray([]);
    } else {
      const overlappingReservations = reservations.filter((reservation) => {
        const reservationStart = reservation.start_time;
        const reservationEnd = reservation.end_time;
        return (
          reservation.id !== reservationId &&
          !(endTime <= reservationStart || startTime >= reservationEnd) &&
          !excludedStatuses.includes(reservation.status)
        );
      });

      const overlappingTables = overlappingReservations.reduce<Array<string | number>>(
        (aggregatedTableIds, reservation: IReservation) => {
          reservation.tables.forEach((tableId: string | number) => {
            if (!aggregatedTableIds.includes(tableId)) {
              aggregatedTableIds.push(tableId);
            }
          });
          return aggregatedTableIds;
        },
        [],
      );

      setOverlappingTablesArray(overlappingTables);
    }
  }, [
    reservations,
    reservation,
    startTime,
    endTime,
    time.start,
    time.end,
    selectedTables,
    status,
    type,
  ]);

  const handleTableClick = (tableValue: string) => {
    if (isLocked) return;
    let selectedTables = getValues(name);

    if (selectedTables.includes(tableValue)) {
      selectedTables = selectedTables.filter((v: string) => v !== tableValue);
    } else {
      selectedTables.push(tableValue);
    }
    setValue(name, selectedTables, { shouldValidate: true });
  };

  const toggleLockTables = () => {
    setValue(bookingFormFieldNames.lock_table, !isLocked);
  };

  const handleSelectAllClick = (roomName: string) => {
    if (isLocked) return;

    // Get all the tables for the specified room
    const roomTables =
      groupedTables
        .find((group) => group.room === roomName)
        ?.tables.map((table) => String(table.id)) || [];

    // Get the current selection of tables
    let currentlySelectedTables = getValues(name);

    // Check if every table in the room is currently selected, excluding overlapping tables
    const isEveryTableSelected = roomTables.every(
      (tableId) =>
        currentlySelectedTables.includes(tableId) || overlappingTablesArray.includes(tableId),
    );

    if (isEveryTableSelected) {
      // If every table in the room is selected, filter them out from the current selection
      // This excludes any tables that are currently overlapping
      currentlySelectedTables = currentlySelectedTables.filter(
        (tableId: string) =>
          !roomTables.includes(tableId) || overlappingTablesArray.includes(tableId),
      );
    } else {
      // If not all tables are selected, add unselected tables from the room that are not overlapping
      const newTablesToAdd = roomTables.filter(
        (tableId) =>
          !currentlySelectedTables.includes(tableId) && !overlappingTablesArray.includes(tableId),
      );
      currentlySelectedTables = [...currentlySelectedTables, ...newTablesToAdd];
    }

    setValue(name, currentlySelectedTables, { shouldValidate: true });
  };

  useEffect(() => {
    const isAnySelectedTableOverlapping = selectedTables.some((tableId: string | number) =>
      overlappingTablesArray.includes(tableId),
    );
    if (isAnySelectedTableOverlapping) {
      setError(bookingFormFieldNames.tables, {
        type: 'manual',
        message: t('overlappingTablesError'),
      });

      isSubmitAvailable && dispatch({ type: AppActionTypes.DisableSubmit });
    } else {
      !isSubmitAvailable && dispatch({ type: AppActionTypes.EnableSubmit });
    }
  }, [selectedTables, overlappingTablesArray]);

  const flattedGroups = groupedTables.flatMap((item) => item.tables);
  const isEmptySelectedTables = selectedTables.length === 0;

  const availableTablesFullStyles = generateFlexBasisStyles({
    startingCount: 4,
    gap: 8,
    incrementStep: 120,
    maxCount: 12,
  });

  const simplifiedTablesFullStyles = generateFlexBasisStyles({
    startingCount: 4,
    gap: 8,
    incrementStep: 120,
    maxCount: isMax900 ? 8 : 7,
  });

  const renderTableButton = ({ tableIndex, table, isSelected, sx }: any) => {
    return (
      <TableButton
        key={tableIndex}
        firstText={`${table.name}`}
        secondText={`${table.min_guests} - ${table.max_guests}`}
        onClick={() => handleTableClick(String(table.id))}
        isSelected={isSelected}
        isOverlapping={overlappingTablesArray.includes(String(table.id))}
        color={isLocked && isSelected ? 'error' : 'secondary'}
        disabled={
          (overlappingTablesArray.includes(String(table.id)) &&
            !selectedTables.includes(String(table.id))) ||
          isReadOnly
        }
        sx={sx}
      />
    );
  };

  if (view === 'floor_plan') {
    return <StyledTypography>{t('floorPlanInProgress')}</StyledTypography>;
  }

  const calculateGuestTotals = (tables: ITable[]) => {
    return tables.reduce(
      (acc, table) => {
        acc.min += table.min_guests;
        acc.max += table.max_guests;
        return acc;
      },
      { min: 0, max: 0 },
    );
  };

  const filteredTablesBySelected = flattedGroups.filter((table) =>
    value.includes(String(table.id)),
  );
  const { min, max } = calculateGuestTotals(filteredTablesBySelected);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          columnGap: '8px',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Heading>{t('availableTablesFor')}</Heading>
        {isSimplified && !isEmptySelectedTables && (
          <CustomNarrowButton
            name={t('lockTable')}
            color="error"
            variant={isLocked ? 'contained' : 'text'}
            onClick={toggleLockTables}
            startIcon={<LockIcon />}
            textVariantBgColor="chablis"
            disabled={isReadOnly}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          ...(isSimplified && { gap: '8px' }),
        }}
      >
        {isSimplified && !isEmptySelectedTables && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', justifyContent: 'flex-start' }}>
            {flattedGroups.map((table, tableIndex) => {
              const isSelected = value.includes(String(table.id));
              if (!isSelected) return null;
              return renderTableButton({
                tableIndex,
                table,
                isSelected,
                sx: {
                  flex: 'unset',
                  padding: '5px',
                  ...simplifiedTablesFullStyles,
                },
              });
            })}
          </Box>
        )}
        {isFull &&
          groupedTables.map((group, index) => {
            const allSelected = group.tables.every(
              (table) =>
                value.includes(String(table.id)) ||
                overlappingTablesArray.includes(String(table.id)),
            );
            return (
              <Box key={index} sx={{ width: '100%' }}>
                <MuiCheckbox
                  name={`selectAllTables${index}`}
                  checked={allSelected}
                  onChange={() => handleSelectAllClick(group.room)}
                  label={group.room}
                  sx={{ marginBottom: '8px' }}
                  disabled={isReadOnly}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '8px',
                    justifyContent: 'flex-start',
                    '& > button': {
                      ...availableTablesFullStyles,
                    },
                  }}
                >
                  {group.tables.map((table, tableIndex) => {
                    const isSelected = value.includes(String(table.id));
                    return renderTableButton({
                      tableIndex,
                      table,
                      isSelected,
                      sx: {},
                    });
                  })}
                </Box>
              </Box>
            );
          })}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography sx={{ fontSize: '12px', fontWeight: 600, color: 'comet' }}>
            {t('minMaxGuestsInfo', { min, max })}
          </Typography>
          {isSimplified && (
            <CustomNarrowButton
              name={t('selectTablesButton')}
              color="secondary"
              onClick={() => dispatch(bookingActions.setView('tables'))}
              endIcon={<ArrowIcon />}
              variant="outlined"
              sx={{ width: 'fit-content' }}
              buttonRef={tablesRef}
            />
          )}
          <CustomErrorMessage errors={errors} name={name} />
        </Box>
      </Box>
    </Box>
  );
};
