import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IBlockedTime, IOpeningHour } from 'types/app/openingHours';
import {
  blockReservationHoursCall,
  getOpeningHoursCall,
} from 'api/app/restaurant/settings/openingHours';
import {
  BlockedHours,
  BlockedHoursHttpPayload,
} from 'app/components/NewSidebar/_components/BlockedHoursDrawer';
import { AppActionTypes } from 'types/app/app';
import { RootState } from 'redux/store';
import { enqueueSnackbar } from 'notistack';

export type OpeningHoursState = IOpeningHour[];

export const initialState: OpeningHoursState = [];

export const fetchOpeningHours = createAsyncThunk(
  'openingHours/fetch.opening.hours',
  async (_, { dispatch }) => {
    const response = await getOpeningHoursCall();
    dispatch(openingHoursSlice.actions.updateOpeningHours(response));

    return response;
  },
);

export const blockReservationHours = createAsyncThunk(
  'openingHours/block.reservation.hours',
  async (data: BlockedHoursHttpPayload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const currentDate = state.app.app.current_date;
    const response = await blockReservationHoursCall(data);

    const result: IBlockedTime[] = Object.entries(data.hours as BlockedHours).map(
      ([room_id, times]) => ({
        room_id: parseInt(room_id, 10),
        times: times.map((time) => {
          const [hours, minutes] = time.split(':').map(Number);
          return currentDate + hours * 3600 + minutes * 60; // Convert to seconds from midnight
        }),
      }),
    );

    dispatch({
      type: AppActionTypes.UpdateOpeningHourBlockedTimes,
      payload: result,
    });

    return response;
  },
);

export const openingHoursSlice = createSlice({
  name: 'openingHours',
  initialState,
  reducers: {
    updateOpeningHours: (_, action) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(blockReservationHours.fulfilled, () => {
      enqueueSnackbar('Blocked hours updated.', { variant: 'success' });
    });
    builder.addCase(blockReservationHours.rejected, () => {
      enqueueSnackbar('An error occurred. Please try again later.', { variant: 'error' });
    });
  },
});

export const actions = { ...openingHoursSlice.actions, fetchOpeningHours, blockReservationHours };
export const reducer = openingHoursSlice.reducer;
