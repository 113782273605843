import { Box, ButtonBase, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectReservationTypes } from 'redux/selectors/booking';
import { actions as bookingActions } from 'redux/slices/bookingSlice';
import { useScreenSize } from 'hooks/useScreenSize';
import { CustomSelect } from 'app/components/FormElements/CustomSelect';
import { ReservationType } from 'types/reservation';
import { disabledTypes } from 'app/components/BookingModal/_config';

export const BookingGroups = () => {
  const dispatch = useAppDispatch();
  const types = useAppSelector(selectReservationTypes);
  const selectedType = useAppSelector((state) => state.booking.type);
  const isNew = useAppSelector((state) => state.booking.isNew);
  const theme = useTheme();
  const { isMobile } = useScreenSize();

  if (isMobile) {
    return (
      <CustomSelect
        options={types}
        value={selectedType}
        onChange={(event) =>
          dispatch(bookingActions.setType(event.target.value as ReservationType))
        }
        sx={{
          maxWidth: 'fit-content',
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: theme.customSpacing.bookingModal,
        flexDirection: { xs: 'row', md: 'column' },
        flexWrap: 'wrap',
      }}
    >
      {types.map(({ value, label, icon, colors }) => {
        const isBlocked =
          (!isNew && value === ReservationType.BLOCKED) || disabledTypes.includes(value);
        return (
          <ButtonBase
            key={value}
            disabled={isBlocked}
            onClick={() => dispatch(bookingActions.setType(value))}
            sx={{
              transition: 'background-color 0.25s ease',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: selectedType === value ? colors.firstColor : 'brandWhite',
              width: '64px',
              height: '64px',
              borderRadius: theme.borderRadius.small,
              color: colors.firstColor,
              gap: '4px',
              '& svg': {
                transition: 'fill 0.25s ease',
              },
              '& svg path': {
                fill: selectedType === value ? 'white' : colors.firstColor,
              },
              '&:hover': {
                backgroundColor: selectedType === value ? colors.firstColor : colors.secondColor,
              },
              ...(isBlocked && {
                opacity: 0.5,
              }),
            }}
          >
            {icon}
            <Typography
              sx={{
                fontSize: '10px',
                fontWeight: 600,
                textAlign: 'center',
                maxWidth: '52px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                color: selectedType === value ? 'white' : colors.firstColor,
              }}
            >
              {label}
            </Typography>
          </ButtonBase>
        );
      })}
    </Box>
  );
};
