import React, { ReactElement, useState } from 'react';
import { Select, MenuItem, Typography, FormControl, SxProps, Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material';
import { CustomErrorMessage } from 'app/components/CustomErrorMessage';
import { useTranslation } from 'react-i18next';
import { CustomLabel } from 'app/components/CustomLabel';
import { TurnoverLabel } from 'app/components/BookingModal/_components/ReservationSection/_components/DateAndTime/_components/TurnoverLabel';
import { AvailableSlots } from 'types/app/availableSlots';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { convertSecondsToHours } from 'utils/date-time/convertSecondsToHours';
import { createMomentUtc } from 'utils/date-time/createMomentUtc';
import { TimeSelectRenderValue } from 'app/components/BookingModal/_components/ReservationSection/_components/DateAndTime/_components/TimeSelect/_components/TimeSelectRenderValue';
import { timeFormat } from 'utils/date-time/dates';
import { useAppSelector } from 'redux/hooks';
dayjs.extend(duration);

type Props = {
  name: string;
  options: AvailableSlots;
  customOnChange?: (value: SelectChangeEvent<HTMLInputElement>) => void;
  variant?: string;
  icon?: ReactElement;
  placeholder?: string;
  label?: string | null;
  defaultLabel?: string | null;
  emptySelectInformation?: string;
  description?: string | null;
  sx?: SxProps;
  translateLabels?: boolean;
  disabled?: boolean;
  shouldValidate?: boolean;
};

const calculateMaxBookingLength = (available_booking_length: number, max_booking_length: number) =>
  available_booking_length > max_booking_length ? max_booking_length : available_booking_length;

export const TimeSelect = ({
  name,
  options,
  customOnChange,
  label,
  defaultLabel,
  emptySelectInformation,
  description,
  disabled = false,
  placeholder,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { control } = useFormContext();
  const dateFormat = useAppSelector((state) => state.app.settings.time_and_language.date_format);

  const {
    field,
    formState: { errors },
  } = useController({
    name,
    control,
  });

  const handleOnChange = (e: SelectChangeEvent<HTMLInputElement>) => {
    if (customOnChange) {
      customOnChange(e);
    }
    field.onChange(e, { shouldValidate: true });
  };

  const optionsToRender = options.length ? (
    options.map(({ timestamp, available_booking_length, max_booking_length, available }) => {
      const maxBookingLength = calculateMaxBookingLength(
        available_booking_length,
        max_booking_length,
      );
      return (
        <MenuItem key={timestamp} value={timestamp} disabled={!available}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={'space-between'}
            spacing={1}
            width={'100%'}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {createMomentUtc(timestamp).format(timeFormat(dateFormat))}
            {max_booking_length > available_booking_length && (
              <>
                <Typography color="accent.red">
                  {createMomentUtc(timestamp + maxBookingLength).format(timeFormat(dateFormat))}
                </Typography>
                <TurnoverLabel
                  color={'accent.gray'}
                  label={convertSecondsToHours(maxBookingLength) || ''}
                />
              </>
            )}
          </Stack>
        </MenuItem>
      );
    })
  ) : (
    <MenuItem value="">{emptySelectInformation || t('selectionOptionsAreUnavailable')}</MenuItem>
  );

  return (
    <FormControl sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <CustomLabel
        label={label}
        name={name}
        description={description}
        defaultLabel={defaultLabel}
      />
      <Select
        fullWidth
        displayEmpty={true}
        {...field}
        label={defaultLabel}
        onChange={handleOnChange}
        IconComponent={() => null}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        disabled={disabled}
        error={!!errors[name]}
        MenuProps={{
          PaperProps: {
            sx: {
              width: 250,
              left: 0,
            },
          },
        }}
        sx={{
          '& .MuiSelect-select': {
            py: 0,
            paddingRight: '14px !important',
            height: '41px !important',
            display: 'flex',
            alignItems: 'center',
          },
        }}
        renderValue={() => <TimeSelectRenderValue isOpen={isOpen} placeholder={placeholder} />}
      >
        {optionsToRender}
      </Select>
      <CustomErrorMessage errors={errors} name={name} />
    </FormControl>
  );
};
