import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';
import { BlockedHours } from 'app/components/NewSidebar/_components/BlockedHoursDrawer';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const currentOpeningHours = (state: RootState) => state.app.app.opening_hour;

export const selectCurrentOpeningHours = createSelector(currentOpeningHours, (opening_hour) => {
  return opening_hour;
});

export const selectBlockedHoursOldVersion = createSelector(currentOpeningHours, (opening_hour) => {
  let blockedHour: BlockedHours = {};

  opening_hour.blocked_times.forEach((blocked_time) => {
    const roomId = blocked_time.room_id;
    const formattedTimes = blocked_time.times.map((timestamp) =>
      dayjs.unix(timestamp).utc().format('HH:mm'),
    );

    blockedHour = {
      ...blockedHour,
      [roomId]: formattedTimes,
    };
  });

  return blockedHour;
});
