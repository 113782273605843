import { useAppDispatch, useAppSelector } from 'redux/hooks';
import legacyBookingActions from 'redux/actions/app/booking';
import reservationsActions from 'redux/actions/app/reservations';
import { MessageVariants } from 'enums/notifications';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Box } from '@mui/material';
import { useState } from 'react';
import { useStatus } from 'hooks/useStatus';
import { actions as bookingActions } from 'redux/slices/bookingSlice';
import { ReservationType } from 'types/reservation';

export const DeleteReservationButton = () => {
  const dispatch = useAppDispatch();
  const type = useAppSelector((state) => state.booking.type);
  const { isReadOnly } = useStatus();
  const [showButtons, setShowButtons] = useState(false);
  const id = useAppSelector((state) => state.booking.reservation?.id);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const deleteReservation = async (id: number) => {
    try {
      await dispatch(legacyBookingActions.deleteReservation(id));
      await dispatch(reservationsActions.getFullReservationsForDay());

      enqueueSnackbar(t('deleteReservationSuccess'), { variant: MessageVariants.Success });
    } catch (error) {
      enqueueSnackbar(t('deleteReservationError'), { variant: MessageVariants.Error });
    } finally {
      dispatch(bookingActions.closeModal());
      dispatch(bookingActions.reset());
      setShowButtons(false);
    }
  };

  const showDeleteButton =
    id && [ReservationType.BLOCKED, ReservationType.WAITING_LIST].includes(type);

  if (!showDeleteButton) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'row', md: 'column' },
        gap: '8px',
        flexWrap: 'wrap',
      }}
    >
      <Button
        color="error"
        onClick={() => setShowButtons(true)}
        sx={{ maxWidth: '64px' }}
        disabled={isReadOnly}
      >
        <Typography sx={{ wordBreak: 'break-word' }}>{t('deleteReservation')}</Typography>
      </Button>
      {showButtons && (
        <>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => deleteReservation(id)}
            sx={{ maxWidth: '64px' }}
          >
            <Typography sx={{ wordBreak: 'break-word' }}>{t('yes')}</Typography>
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => setShowButtons(false)}
            sx={{ maxWidth: '64px' }}
          >
            <Typography sx={{ wordBreak: 'break-word' }}>{t('no')}</Typography>
          </Button>
        </>
      )}
    </Box>
  );
};
