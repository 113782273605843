import { Box, IconButton } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'images/icons/ic-trash.svg';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TimeRangeModal from 'app/pages/RestaurantSettings/TimeManagement/_components/TimeRangeModal';

interface OwnProps {
  onDelete: () => void;
  allowToRemove: boolean;
  index: number;
}

export const ReservationLengthEdit = ({ onDelete, allowToRemove, index }: OwnProps) => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <Box sx={{ display: 'flex' }}>
      {allowToRemove && (
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      )}

      <Button
        variant={'outlined'}
        onClick={() => setModalIsOpen(true)}
        color={'secondary'}
        size={'small'}
      >
        {t('edit')}
      </Button>

      <TimeRangeModal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} index={index} />
    </Box>
  );
};
