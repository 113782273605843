import { ControlledInput } from 'app/components/FormElements/ControlledInput';
import { MutableRefObject } from 'react';

type Props = {
  guestsRef?: MutableRefObject<any>;
  isReadOnly: boolean;
  name: string;
  onChange: (value: number) => void;
};

export const CustomNumberOfGuests = ({ guestsRef, isReadOnly, name, onChange }: Props) => (
  <ControlledInput
    disabled={isReadOnly}
    fullWidth={true}
    name={name}
    hideError={true}
    type="number"
    customOnChange={(_, e) => {
      const value = parseInt(e.target.value);
      return onChange(value);
    }}
    typeNumberSx={{
      flexDirection: 'column-reverse',
      svg: {
        width: 13,
        height: 13,
      },
    }}
    sx={{
      '& .MuiInputBase-root': {
        paddingLeft: 0,
        paddingRight: 1,
      },
    }}
    inputBaseSx={{ paddingLeft: 0, textAlign: 'center' }}
    inputRef={guestsRef}
  />
);
