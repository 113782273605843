import { Box } from '@mui/material';
import { CustomCard } from 'app/components/BookingModal/_components/CustomCard';
import { Heading } from 'app/components/Heading';
import { useAppSelector } from 'redux/hooks';
import { useTranslation } from 'react-i18next';
import { IWidgetField } from 'types/app/reservations';
import { snakeToCamelCase } from 'utils/str/snakeToCamelCase';

export const WidgetAdditionalFields = () => {
  const widget_fields = useAppSelector((state) => state.booking.reservation?.widget_fields);
  const { t } = useTranslation();

  if (!widget_fields?.length) {
    return null;
  }

  return (
    <Box>
      <Heading sx={{ marginBottom: 1.5 }}>{t('widgetFields')}</Heading>
      <CustomCard sx={{ borderRadius: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '8px' }}>
          {widget_fields.map((field: IWidgetField) => (
            <div key={field.key}>
              {t(snakeToCamelCase(field.key))}: {field.value}
            </div>
          ))}
        </Box>
      </CustomCard>
    </Box>
  );
};
