export enum Language {
  SQ = 'sq', // albanian
  AR = 'ar', // arabic
  AZ = 'az', // azerbaijani
  BG = 'bg', // bulgarian
  CA = 'ca', // catalan
  ZH_HANS = 'zh-hans', // chineseZhHans
  ZH_HANT = 'zh-hant', // chineseZhHant
  HR = 'hr', // croatian
  CS = 'cs', // czech
  DA = 'da', // danish
  NL = 'nl', // dutch
  EN = 'en', // english
  ET = 'et', // estonian
  FI = 'fi', // finnish
  FR = 'fr', // french
  DE = 'de', // german
  EL = 'el', // greek
  HU = 'hu', // hungarian
  IS = 'is', // icelandic
  IT = 'it', // italian
  JA = 'ja', // japanese
  LV = 'lv', // latvian
  LT = 'lt', // lithuanian
  NN = 'nn', // norwegian
  PL = 'pl', // polish
  PT_PT = 'pt-pt', // portuguese
  RO = 'ro', // romanian
  RU = 'ru', // russian
  SL = 'sl', // slovenian
  ES = 'es', // spanish
  SV = 'sv', // swedish
  TH = 'th', // thai
  VI = 'vi', // vietnamese
}
