import { useAppSelector } from 'redux/hooks';
import { RefObject, useRef } from 'react';

export const useBooking = () => {
  const {
    is_create_reservation_loading,
    is_update_reservation_loading,
    is_delete_reservation_loading,
  } = useAppSelector((state) => state.app.loading);

  const guestsRef = useRef(null);
  const guestNameRef = useRef(null);
  const guestPhoneRef = useRef(null);
  const tablesRef = useRef(null);

  const scrollToElement = (elementRef: RefObject<HTMLElement>) => {
    elementRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const scrollToError = (errors: any, refs: any) => {
    const errorsArray = [
      errors?.guests,
      errors?.tables,
      errors?.client?.name,
      errors?.client?.phone,
    ].filter((error) => error !== undefined);

    if (errorsArray.length > 0) {
      const firstError = errorsArray[0];

      switch (firstError) {
        case errors.guests:
          scrollToElement(refs.guests);
          break;
        case errors.client?.name:
          scrollToElement(refs.client?.name);
          break;
        case errors.client?.phone:
          scrollToElement(refs.client?.phone);
          break;
        case errors.tables:
          scrollToElement(refs.tables);
          break;
        default:
          break;
      }
    }
  };

  const refs = {
    guests: guestsRef,
    client: {
      name: guestNameRef,
      phone: guestPhoneRef,
    },
    tables: tablesRef,
  };

  return {
    isLoading:
      is_create_reservation_loading ||
      is_update_reservation_loading ||
      is_delete_reservation_loading,
    refs,
    scrollToError,
  };
};
