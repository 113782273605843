import { initialState } from 'redux/reducers/index';
import { BookingAction, BookingFormData } from 'types/app/booking';
import { BookingActionTypes } from 'types/app/booking';

export const bookingReducer = (
  state: BookingFormData = initialState.app.booking,
  action: BookingAction,
): BookingFormData => {
  switch (action.type) {
    case BookingActionTypes.UpdateBooking:
      return {
        ...action.payload,
      };
    case BookingActionTypes.ResetBooking:
      return {
        ...action.payload,
      };
    case BookingActionTypes.UpdateReservationClient:
      return {
        ...state,
        client: action.payload,
      };
    default:
      return state;
  }
};
