import { useTranslation } from 'react-i18next';
import { Box, SelectChangeEvent } from '@mui/material';
import { Heading } from 'app/components/Heading';
import { MuiSelect } from 'app/components/FormElements/MuiSelect';
import { ReactComponent as ClockIcon } from 'images/icons/ic-clock.svg';
import { bookingFormFieldNames } from 'app/components/BookingModal/_config';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectCurrentOpeningHours } from 'redux/selectors/openingHours';
import { useEffect, useMemo, useState } from 'react';
import { isEqual, range } from 'lodash';
import { createMomentUtc } from 'utils/date-time/createMomentUtc';
import { useFormContext } from 'react-hook-form';
import { useStatus } from 'hooks/useStatus';
import availableSlots from 'redux/actions/app/availableSlots';
import {
  selectAvailableSlotsFromRange,
  selectEndTimesFromRange,
  selectSlotByTimestampAndGuests,
} from 'redux/selectors/availableSlots';
import { TimeSelect } from 'app/components/BookingModal/_components/ReservationSection/_components/DateAndTime/_components/TimeSelect';
import { timeFormat } from 'utils/date-time/dates';

export const DateAndTime = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { time } = useAppSelector(selectCurrentOpeningHours);
  const interval = useAppSelector((state) => state.app.settings.reservation.interval);
  const dateFormat = useAppSelector((state) => state.app.settings.time_and_language.date_format);
  const id = useAppSelector((state) => state.booking.reservation?.id);
  const booking_length = useAppSelector(
    (state) => state.app.settings.reservation.default_booking_length,
  );
  const dispatch = useAppDispatch();
  const [customBookingLength, setCustomBookingLength] = useState<number | null>(null);

  const { setValue, watch, resetField } = useFormContext();
  const { isReadOnly } = useStatus();

  const startTime = watch(bookingFormFieldNames.start_time);
  const endTime = watch(bookingFormFieldNames.end_time);
  const guests = watch(bookingFormFieldNames.guests);

  const defaultStartTimeOptions = useMemo(() => {
    return range(time.start, time.end, interval).map((time) => {
      return {
        formatted_timestamp: createMomentUtc(time).format(timeFormat(dateFormat)),
        timestamp: time,
        available: true,
        available_booking_length: booking_length,
        max_booking_length: booking_length,
        min_booking_length: booking_length,
        tables: [],
        guests: 0,
      };
    });
  }, [time.start, time.end]);

  const defaultEndTimeOptions = useMemo(() => {
    return range(time.start, time.end + interval, interval).map((time) => {
      return {
        label: createMomentUtc(time).format(timeFormat(dateFormat)),
        value: time,
      };
    });
  }, [time.start, time.end]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(availableSlots.getAvailableSlotsForDay(guests, id))
      .then((slots) => {
        if (startTime && !slots?.find((slot) => slot.timestamp === startTime) && slots?.length) {
          setValue(bookingFormFieldNames.start_time, slots[0].timestamp);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [guests, time.start, time.end]);

  useEffect(() => {
    if (endTime > time.end) {
      setValue(bookingFormFieldNames.end_time, time.end);
    }
  }, [endTime]);

  const active_start_slots = useAppSelector(
    selectSlotByTimestampAndGuests(startTime, guests),
    (prev, next) => isEqual(prev, next),
  );

  const start_slots = useAppSelector(
    selectAvailableSlotsFromRange(time.start, time.end, guests, true),
    (prev, next) => isEqual(prev, next),
  );

  const end_slots = useAppSelector(
    selectEndTimesFromRange(
      active_start_slots
        ? active_start_slots.timestamp + active_start_slots.min_booking_length
        : time.start,
      time.end,
      guests,
    ),
    (prev, next) => isEqual(prev, next),
  );

  const handleStartTimeChange = (e: SelectChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    const activeSlot = start_slots.find(
      (slot) => slot.timestamp === value && slot.guests === guests,
    );
    const slotMaxBookingLength = activeSlot
      ? activeSlot.available_booking_length <= activeSlot.max_booking_length
        ? activeSlot.available_booking_length
        : activeSlot.max_booking_length
      : null;

    const duration = customBookingLength || slotMaxBookingLength || booking_length;
    const newEndValue = value + duration <= time.end ? value + duration : time.end;

    resetField(bookingFormFieldNames.end_time, { keepError: false });
    setValue(bookingFormFieldNames.end_time, newEndValue);
  };

  const handleEndTimeChange = (e: SelectChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value > startTime) {
      setCustomBookingLength(value - startTime);
    }
  };

  return (
    <Box>
      <Heading>{t('dateAndTime')}</Heading>
      <Box sx={{ display: 'flex', gap: '8px', flexDirection: { xs: 'column', md: 'row' } }}>
        <TimeSelect
          sx={{ flex: { md: '0 0 50%', xs: '100%' } }}
          disabled={isReadOnly || isLoading}
          name={bookingFormFieldNames.start_time}
          options={start_slots.length ? start_slots : defaultStartTimeOptions}
          customOnChange={handleStartTimeChange}
          placeholder={
            startTime
              ? createMomentUtc(startTime).format(timeFormat(dateFormat))
              : t('startTimePlaceholder')
          }
        />
        <MuiSelect
          sx={{ flex: { md: '0 0 50%', xs: '100%' } }}
          disabled={isReadOnly || isLoading || !startTime}
          name={bookingFormFieldNames.end_time}
          options={start_slots.length ? end_slots : defaultEndTimeOptions}
          icon={<ClockIcon />}
          translateLabels={false}
          customOnChange={handleEndTimeChange}
          placeholder={
            endTime
              ? createMomentUtc(endTime).format(timeFormat(dateFormat))
              : t('endTimePlaceholder')
          }
        />
      </Box>
    </Box>
  );
};
