import moment, { Duration } from 'moment';
import { DateFormatKey } from 'types/dates';

interface MathMethod {
  ceil: string;
}

export const datesMoment: DateFormatKey = {
  dmy: 'DD/MM/YYYY', //'d/m/Y H:i:s',
  dmya: 'DD/MM/YYYY', //'d/m/Y h:i:s A',
  mdy: 'MM/DD/YYYY', //'m/d/Y H:i:s',
  mdya: 'MM/DD/YYYY', //'m/d/Y h:i:s A',
  ymd: 'YYYY-MM-DD', //'Y-m-d H:i:s',
};

const times: DateFormatKey = {
  dmy: 'HH:mm', //'d/m/Y H:i:s',
  dmya: 'hh:mm A', //'d/m/Y h:i:s A',
  mdy: 'HH:mm', //'m/d/Y H:i:s',
  mdya: 'hh:mm A', //'m/d/Y h:i:s A',
  ymd: 'HH:mm', //'Y-m-d H:i:s',
};

export const dateFormatDate = (formatKey?: keyof DateFormatKey) => {
  return typeof formatKey !== 'undefined' && datesMoment[formatKey] !== 'undefined'
    ? datesMoment[formatKey]
    : datesMoment['dmy'];
};

export const timeFormat = (formatKey: keyof DateFormatKey) => {
  return times[formatKey] !== 'undefined' ? times[formatKey] : times['dmy'];
};

export const DateTimeFormat = (formatKey: keyof DateFormatKey) => {
  return (
    (datesMoment[formatKey] !== 'undefined' ? datesMoment[formatKey] : datesMoment['dmy']) +
    ' - ' +
    (times[formatKey] !== 'undefined' ? times[formatKey] : times['dmy'])
  );
};

export const roundDate = (date: number, duration: Duration, method: keyof MathMethod) => {
  return moment(Math[method](+date / +duration) * +duration).valueOf();
};
