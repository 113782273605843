export const serviceFieldNames = {
  name: 'name',
  valid_days: 'valid_days',
  start_time: 'start_time',
  end_time: 'end_time',
};

export const timeManagementFieldNames: any = {
  ...serviceFieldNames,
  ranges: 'ranges',
  min_guests: 'min_guests',
  max_guests: 'max_guests',
  min_length: 'min_length',
  min_phone_length: 'min_phone_length',
  max_length: 'max_length',
};

export const timeManagementHeadRows = [
  { name: 'timeManagementGuests' },
  { name: 'timeManagementDefaultBookingLength' },
  { name: 'timeManagementMinPhoneBookingLength' },
  { name: 'timeManagementMinWidgetBookingLength' },
  { name: '' },
];

export const oneHourInSeconds = 3600;
export const twoHoursInSeconds = 7200;

const customBookingLengthDefaultValues = {
  [timeManagementFieldNames.name]: '',
  [timeManagementFieldNames.valid_days]: [],
  [timeManagementFieldNames.start_time]: 0,
  [timeManagementFieldNames.end_time]: 0,
};

const defaultBookingLengthDefaultValues = {
  [timeManagementFieldNames.ranges]: [],
  [timeManagementFieldNames.min_guests]: 1,
  [timeManagementFieldNames.max_guests]: 2,
  [timeManagementFieldNames.min_length]: oneHourInSeconds,
  [timeManagementFieldNames.min_phone_length]: oneHourInSeconds,
  [timeManagementFieldNames.max_length]: twoHoursInSeconds,
};

export const tableDefaultValues: any = {
  ...customBookingLengthDefaultValues,
  ...defaultBookingLengthDefaultValues,
};
