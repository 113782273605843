import { createSlice } from '@reduxjs/toolkit';
import { Clients } from 'types/app/clients';
import { findIndex } from 'lodash';
import { fetchBookingClient } from 'redux/slices/bookingSlice';

export type ClientState = Clients;

export const initialState: ClientState = [];

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBookingClient.fulfilled, (state, action) => {
      const index = findIndex(state, { id: action.payload.id });

      if (index !== -1) {
        return [...state.slice(0, index), action.payload, ...state.slice(index + 1)];
      } else {
        return [...state, action.payload];
      }
    });
  },
});

export const actions = { ...clientsSlice.actions };
export const reducer = clientsSlice.reducer;
