import { useFieldArray, useFormContext } from 'react-hook-form';
import { TableCell, Typography } from '@mui/material';
import { StyledTableRow } from 'app/pages/RestaurantSettings/_components/StyledTableRow';
import { ReservationLengthEdit } from 'app/pages/RestaurantSettings/TimeManagement/_components/ReservationLengthEdit';
import { MinMaxGuestsRange } from 'app/pages/RestaurantSettings/TimeManagement/_components/MinMaxGuestsRange';
import { timeManagementFieldNames } from 'app/pages/RestaurantSettings/TimeManagement/_config';
import { convertSecondsToHours } from 'utils/date-time/convertSecondsToHours';

interface OwnProps {
  name: string;
}

const inputStyles = {
  width: '46px',
  textAlign: 'center',
  '& .MuiInputBase-input': {
    paddingTop: '10px',
    paddingBottom: '10px',
    textAlign: 'center',
  },
  '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
    {
      WebkitAppearance: 'none',
      margin: 0,
    },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
};

export const TableRowRanges = ({ name }: OwnProps) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const { remove } = useFieldArray({
    control,
    name,
  });
  const fields = watch(name);
  const rangeCanBeRemoved = fields.length > 1;

  const handleRemove = (index: number) => {
    if (rangeCanBeRemoved) {
      remove(index);
    }
  };

  return (
    <>
      {fields.map((field: any, index: number) => (
        <StyledTableRow key={field.id}>
          <TableCell>
            <MinMaxGuestsRange
              isEdit={false}
              minName={`${name}.${index}.${timeManagementFieldNames.min_guests}`}
              maxName={`${name}.${index}.${timeManagementFieldNames.max_guests}`}
              control={control}
              errors={errors}
              inputStyles={inputStyles}
              field={field}
            />
          </TableCell>
          <TableCell>
            <Typography>{convertSecondsToHours(field.max_length)}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{convertSecondsToHours(field.min_phone_length)}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{convertSecondsToHours(field.min_length)}</Typography>
          </TableCell>
          <TableCell>
            <ReservationLengthEdit
              allowToRemove={rangeCanBeRemoved}
              onDelete={() => handleRemove(index)}
              index={index}
            />
          </TableCell>
        </StyledTableRow>
      ))}
    </>
  );
};
