import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const clients = (state: RootState) => state.clients;
const booking = (state: RootState) => state.booking;

export const selectClientStatsById = (id: number) =>
  createSelector([clients], (clients) => clients.find((client) => client.id === id)?.stats);

export const selectBookingClientStats = createSelector(
  [clients, booking],
  (clients, booking) => clients.find((client) => client.id === booking?.client?.id)?.stats,
);
