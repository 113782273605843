import { Box } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import { useTranslation } from 'react-i18next';

export const ShortBookingInfo = () => {
  const { t } = useTranslation();
  const reservation = useAppSelector((state) => state.booking.reservation);

  if (!reservation) {
    return null;
  }

  const { id, origin, channel } = reservation;
  const source = channel ? channel : t(origin);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        flexWrap: 'wrap',
      }}
    >
      {`#${id}`}
      <> · </>
      {source}
    </Box>
  );
};
