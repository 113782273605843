import { styled } from '@mui/material';

export const BlurCurtain = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  backdropFilter: 'blur(3px)',
  zIndex: 2,
  cursor: 'not-allowed',
  borderRadius: theme.shape.borderRadius,
}));
