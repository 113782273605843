import { IBlockedTime, IOpeningHour } from 'types/app/openingHours';
import { Statuses } from 'types/reservation';
import { IReservation } from 'types/app/reservations';

export enum AppActionTypes {
  EnableSubmit = 'App.EnableSubmit',
  DisableSubmit = 'App.DisableSubmit',
  BookingModalOpened = 'BookingModal.Opened',
  BookingModalClosed = 'BookingModal.Closed',
  ChangeCurrentDate = 'App.ChangeCurrentDate',
  UpdateDisplayedDates = 'App.UpdateDisplayedDates',
  UpdateOpeningHour = 'App.UpdateOpeningHour',
  UpdateOpeningHourBlockedTimes = 'App.UpdateOpeningHourBlockedTimes',
  SubmitStartPending = 'App.SubmitStartPending',
  SubmitEndPending = 'App.SubmitEndPending',
  UpdateFilters = 'App.UpdateFilters',
  ResetFilters = 'App.ResetFilters',
  UpdateSearch = 'App.UpdateSearch',
  ResetSearch = 'App.ResetSearch',
  UpdateSearchTime = 'App.UpdateSearchTime',
  UpdateSearchField = 'App.UpdateSearchField',
  ResetSearchedReservations = 'App.ResetSearchedReservations',
  UpdateSearchedReservations = 'App.UpdateSearchedReservations',
  LoadMoreSearchedReservations = 'App.LoadMoreSearchedReservations',
  SetCurrentPage = 'App.SetCurrentPage',
}

export interface IFilters {
  rooms: number[];
  reservation_tags: string[];
  special_offers: number[];
  reservation: string[];
  statuses: Statuses[];
  guests: string[];
  guests_options: string[];
}

export interface ISearchReservations {
  start_time: number | null;
  end_time: number | null;
  reservation_id?: string | null;
  guest_name?: string | null;
  guest_phone?: string | null;
  guest_email?: string | null;
  guest_company?: string | null;
}

export interface ISearchReservationsData extends ISearchReservations {
  limit: number | null;
  page: number | null;
}

export interface ISearchedReservations {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  data: IReservation[];
}

export interface ISearchTime {
  start_time: null | number;
  end_time: null | number;
}

export interface UpdateSearchFieldPayload {
  key: keyof ISearchReservations;
  value: string;
}

interface ChangeCurrentDateAction {
  type: AppActionTypes.ChangeCurrentDate;
  payload: { current_date: number };
}

interface UpdateDisplayedDatesAction {
  type: AppActionTypes.UpdateDisplayedDates;
  payload: { displayed_dates: number[] };
}

interface UpdateOpeningHourAction {
  type: AppActionTypes.UpdateOpeningHour;
  payload: IOpeningHour;
}

interface UpdateOpeningHourBlockedTimesAction {
  type: AppActionTypes.UpdateOpeningHourBlockedTimes;
  payload: IBlockedTime[];
}

interface UpdateFiltersAction {
  type: AppActionTypes.UpdateFilters;
  payload: IFilters;
}

interface ResetFiltersAction {
  type: AppActionTypes.ResetFilters;
  payload: IFilters;
}

interface UpdateSearchAction {
  type: AppActionTypes.UpdateSearch;
  payload: ISearchReservations;
}

interface ResetSearchAction {
  type: AppActionTypes.ResetSearch;
  payload: ISearchReservations;
}

interface UpdateSearchFieldAction {
  type: AppActionTypes.UpdateSearchField;
  payload: UpdateSearchFieldPayload;
}

interface UpdateSearchTimeAction {
  type: AppActionTypes.UpdateSearchTime;
  payload: ISearchTime;
}

interface UpdateSearchedReservationsAction {
  type: AppActionTypes.UpdateSearchedReservations;
  payload: ISearchReservationsData;
}

interface ResetSearchedReservationsAction {
  type: AppActionTypes.ResetSearchedReservations;
  payload: ISearchedReservations;
}

interface LoadMoreSearchedReservationsAction {
  type: AppActionTypes.LoadMoreSearchedReservations;
  payload: ISearchedReservations;
}

interface SetCurrentPageAction {
  type: AppActionTypes.SetCurrentPage;
  payload: number;
}

export interface AppConfigState {
  current_date: number;
  displayed_dates: number[];
  opening_hour: IOpeningHour;
  filters: IFilters;
  search: ISearchReservations;
  searched_reservations: ISearchedReservations;
  is_submit_available: boolean;
  is_submit_pending: boolean;
}

interface SimpleAction {
  type:
    | AppActionTypes.EnableSubmit
    | AppActionTypes.DisableSubmit
    | AppActionTypes.BookingModalOpened
    | AppActionTypes.BookingModalClosed
    | AppActionTypes.SubmitStartPending
    | AppActionTypes.SubmitEndPending;
}

export type AppActions =
  | ChangeCurrentDateAction
  | UpdateDisplayedDatesAction
  | UpdateOpeningHourAction
  | UpdateFiltersAction
  | ResetFiltersAction
  | UpdateSearchAction
  | UpdateSearchFieldAction
  | ResetSearchAction
  | UpdateSearchTimeAction
  | SimpleAction
  | UpdateSearchedReservationsAction
  | ResetSearchedReservationsAction
  | LoadMoreSearchedReservationsAction
  | SetCurrentPageAction
  | UpdateOpeningHourBlockedTimesAction;
