import { Box } from '@mui/material';
import { BookingGroups } from 'app/components/BookingModal/_components/BookingGroups';
import { DeleteReservationButton } from 'app/components/BookingModal/_components/DeleteReservationButton';
import { useTheme } from '@mui/material/styles';
import { useScreenSize } from 'hooks/useScreenSize';

export const BookingLeftBar = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSize();

  if (isMobile) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: { xs: 'row', md: 'column', gap: theme.customSpacing.bookingModal },
      }}
    >
      <BookingGroups />
      <DeleteReservationButton />
    </Box>
  );
};
