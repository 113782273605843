import { SideModal } from 'app/components/Modals/SideModal';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ControlledInput } from 'app/components/FormElements/ControlledInput';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  oneHourInSeconds,
  tableDefaultValues,
  timeManagementFieldNames,
} from 'app/pages/RestaurantSettings/TimeManagement/_config';
import { Box } from '@mui/material';
import { useStatus } from 'hooks/useStatus';
import * as yup from 'yup';
import { WeekdaySelector } from 'app/components/WeekdaySelector';
import { StartEndTimePickers } from 'app/components/StartEndTimePickers';
import { MessageVariants } from 'enums/notifications';
import { useAppDispatch } from 'redux/hooks';
import { useSnackbar } from 'notistack';
import timeManagementActions from 'redux/actions/app/timeManagement';
import { CustomBookingLength, TimeManagementRange } from 'types/app/timeManagement';
import { useEffect, useState } from 'react';

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
}

const rangeDefaultValue = {
  [timeManagementFieldNames.min_guests]: 1,
  [timeManagementFieldNames.max_guests]: 1,
  [timeManagementFieldNames.min_length]: oneHourInSeconds,
  [timeManagementFieldNames.max_length]: oneHourInSeconds,
  [timeManagementFieldNames.min_phone_length]: oneHourInSeconds,
};

export const ServiceModal = ({ isOpen, onClose }: OwnProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { isReadOnly } = useStatus();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const schema = yup.object().shape({
    name: yup.string().required(t('serviceNameIsRequired')),
    valid_days: yup.array().of(yup.number()).min(1, t('atLeastOneDayRequired')),
  });

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: tableDefaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, watch } = methods;

  const startTime: any = watch(timeManagementFieldNames.start_time);
  const endTime: any = watch(timeManagementFieldNames.end_time);

  const isValidStartTime = startTime <= endTime;

  const onReset = () => {
    reset(tableDefaultValues);
  };

  const onModalClose = () => {
    onClose();
    onReset();
  };

  const onSubmit = (data: CustomBookingLength) => {
    setIsLoading(true);
    const { name, valid_days, start_time, end_time } = data;
    const snackbar = {
      message: t('createCustomBookingLengthSuccess'),
      variant: MessageVariants.Success,
    };

    dispatch(
      timeManagementActions.createCustomBookingLength({
        name,
        valid_days,
        start_time,
        end_time,
        ranges: [rangeDefaultValue] as unknown as TimeManagementRange[],
      }),
    )
      .catch(() => {
        snackbar.message = t('createCustomBookingLengthError');
        snackbar.variant = MessageVariants.Error;
      })
      .finally(() => {
        enqueueSnackbar(snackbar.message, { variant: snackbar.variant });
        onModalClose();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    reset();
  }, [onClose]);

  return (
    <>
      <SideModal
        title={t('addServiceTitle')}
        open={isOpen}
        isLoading={isLoading}
        onClose={onModalClose}
        methods={methods}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        saveDisabled={!isValidStartTime || isReadOnly}
        paperSx={{ width: '100%', maxWidth: '1200px' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <ControlledInput label={t('serviceTitle')} name={timeManagementFieldNames.name} />
          <WeekdaySelector name={timeManagementFieldNames.valid_days} label={t('serviceDays')} />
          <StartEndTimePickers
            startName={timeManagementFieldNames.start_time}
            startLabel={t('serviceStartTime')}
            endName={timeManagementFieldNames.end_time}
            endLabel={t('serviceEndTime')}
          />
        </Box>
      </SideModal>
    </>
  );
};
