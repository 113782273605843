import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';

const tables = (state: RootState) => state.app.tables;
const rooms = (state: RootState) => state.app.rooms;

export const selectTables = createSelector([tables], (tables) => tables);

export const selectGroupedTables = createSelector([rooms, tables], (rooms, tables) => {
  return rooms.map((room) => ({
    room: room.name,
    tables: tables.filter((table) => table.room_id === room.id),
  }));
});
