import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { BlockedHoursHttpPayload } from 'app/components/NewSidebar/_components/BlockedHoursDrawer';

export const getOpeningHoursCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.OPENING_HOURS,
    },
    true,
  );
};

export const blockReservationHoursCall = async (data: BlockedHoursHttpPayload) => {
  return await asyncRequest(
    {
      method: 'POST',
      data,
      baseURL: baseUrl,
      url: 'api-app/calendar/restaurant/block-reservation-hours', //old endpoint
    },
    true,
  );
};
