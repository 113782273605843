import { ChangeEvent } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import { ControlledInput } from 'app/components/FormElements/ControlledInput';
import { bookingFormFieldNames } from 'app/components/BookingModal/_config';
import { ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { SideModal } from 'app/components/Modals/SideModal';
import { useStatus } from 'hooks/useStatus';

enum ChargeModeTypes {
  PerPerson = 'per_person',
  PerReservation = 'per_reservation',
}

interface OwnProps {
  onPaymentModalClose: () => void;
}

export const PaymentModal = ({ onPaymentModalClose }: OwnProps) => {
  const { watch } = useFormContext();
  const minAmount = useAppSelector((state) => state.booking.minAmount);

  const guests = watch(bookingFormFieldNames.guests);
  const chargeAmount = watch(bookingFormFieldNames.late_cancellation.amount);

  const booking = useAppSelector((state) => state.booking);
  const lateCancellation = booking.reservation?.special_offer?.late_cancellation;
  const modalIsOpen = useAppSelector(
    (state) => state.calendar.lateCancellationNoShowPayment.paymentModalIsOpen,
  );

  const { t } = useTranslation();
  const chargeMode = lateCancellation?.charge_mode;
  const maxDescriptionLength = 255;
  const currency = lateCancellation?.currency;
  const { isReadOnly } = useStatus();

  const handleChargeAmount = (
    field: ControllerRenderProps<FieldValues, string>,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    let total = e.target.value;

    if (isNaN(Number(total))) {
      total = total.replace(/[^0-9\.]/g, '');
      if (total.split('.')?.length > 2) {
        total = total.replace(/\.+$/, '');
      }
    }

    field.onChange(total);
  };

  const handleOnBlur = (field: ControllerRenderProps<FieldValues, string>) => {
    const numValue = parseFloat(field.value);
    if (!isNaN(numValue)) {
      field.onChange(numValue.toFixed(2));
    }

    field.onBlur();
  };

  return (
    <SideModal
      open={modalIsOpen}
      title={t('paymentModalTitle')}
      onClose={onPaymentModalClose}
      form="booking-form"
      confirmButtonText={t('chargeButton')}
      additionalElements={
        <Button
          sx={{ height: '40px', lineHeight: 1 }}
          color="secondary"
          variant="outlined"
          onClick={onPaymentModalClose}
          disabled={isReadOnly}
        >
          {t('cancel')}
        </Button>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <ControlledInput
          name={bookingFormFieldNames.late_cancellation.amount}
          label={`
          ${t('paymentModalAmountToPay', { currency })} / 
          ${
            chargeMode === ChargeModeTypes.PerReservation
              ? t('paymentModalChargePerReservation')
              : t('paymentModalChargePerPerson')
          }
          `}
          customOnChange={handleChargeAmount}
          onBlur={handleOnBlur}
          disabled={isReadOnly}
        />
        <ControlledInput
          name={bookingFormFieldNames.late_cancellation.description}
          label={t('paymentModalDescription')}
          maxLength={maxDescriptionLength}
          disabled={isReadOnly}
        />
        {parseFloat(chargeAmount) >= minAmount && chargeMode === ChargeModeTypes.PerPerson && (
          <Typography>
            <Trans
              i18nKey="totalChargeCalculation"
              values={{
                amount: parseFloat(chargeAmount),
                currency: currency,
                guests: guests,
                total: parseFloat(chargeAmount) * guests,
              }}
              components={{ strong: <strong /> }}
            />
          </Typography>
        )}
      </Box>
    </SideModal>
  );
};
