import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { AppConnectionStatus, ApplicationState } from 'types/application';

export const initialState: ApplicationState = {
  connectionStatus: {
    isOffline: false,
    isServerAvailable: true,
  },
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateConnectionStatus: (state, action: PayloadAction<AppConnectionStatus>) => {
      state.connectionStatus = action.payload;
    },
  },
});

export const actions = { ...applicationSlice.actions };
export const reducer = applicationSlice.reducer;
