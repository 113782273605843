import { Dispatch } from 'redux';
import { getLocaleCall } from 'api/calendar';

import {
  LateCancellationNoShowPyamentConfirmModalCloseAction,
  LateCancellationNoShowPyamentConfirmModalOpenAction,
  LateCancellationNoShowPyamentModalCloseAction,
  LateCancellationNoShowPyamentModalOpenAction,
  LocaleSetAction,
} from 'types/calendar';

const calendarActions = {
  setLocale() {
    return (dispatch: Dispatch) => {
      getLocaleCall().then((locale) => {
        dispatch({
          type: 'Calendar.LocaleSet',
          payload: locale,
        } as LocaleSetAction);
      });
    };
  },

  openLateCancellationNoShowPaymentConfirmationModal() {
    return {
      type: 'Calendar.LateCancellationNoShow.PaymentConfirmationModalOpen',
      payload: { askForPaymentModalIsOpen: true },
    } as LateCancellationNoShowPyamentConfirmModalOpenAction;
  },
  closeLateCancellationNoShowPaymentConfirmationModal() {
    return {
      type: 'Calendar.LateCancellationNoShow.PaymentConfirmationModalClose',
      payload: { askForPaymentModalIsOpen: false },
    } as LateCancellationNoShowPyamentConfirmModalCloseAction;
  },
  openLateCancellationNoShowPaymentModal() {
    return {
      type: 'Calendar.LateCancellationNoShow.PaymentModalOpen',
      payload: { paymentModalIsOpen: true },
    } as LateCancellationNoShowPyamentModalOpenAction;
  },
  closeLateCancellationNoShowPaymentModal() {
    return {
      type: 'Calendar.LateCancellationNoShow.PaymentModalClose',
      payload: { paymentModalIsOpen: false },
    } as LateCancellationNoShowPyamentModalCloseAction;
  },
};

export default calendarActions;
