import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledTableCell } from 'app/pages/RestaurantSettings/_components/StyledTableCell';
import { CustomTable } from 'app/pages/RestaurantSettings/_components/CustomTable';
import {
  timeManagementFieldNames,
  timeManagementHeadRows,
} from 'app/pages/RestaurantSettings/TimeManagement/_config';
import { TableRowRanges } from 'app/pages/RestaurantSettings/TimeManagement/_components/TableRowRanges';
import TimeRangeModal from 'app/pages/RestaurantSettings/TimeManagement/_components/TimeRangeModal';
import { useState } from 'react';

export const ReservationLengthTable = () => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const headRowsToRender = timeManagementHeadRows.map(({ name }, index) => (
    <StyledTableCell key={index}>{t(name)}</StyledTableCell>
  ));

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <CustomTable
          headRowsToRender={headRowsToRender}
          rowsToRender={<TableRowRanges name={timeManagementFieldNames.ranges} />}
        />
      </Box>

      <Box>
        <Button variant={'outlined'} color={'secondary'} onClick={() => setModalIsOpen(true)}>
          {t('addRange')}
        </Button>

        <TimeRangeModal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} />
      </Box>
    </>
  );
};
