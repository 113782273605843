import { StyledDialog } from 'app/components/Modals/StyledDialog';
import { ReactNode } from 'react';
import { LinearProgress } from '@mui/material';

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  isLoading?: boolean;
}

export const BookingDialog = ({ isOpen, onClose, children, isLoading = false }: OwnProps) => {
  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'center',
          padding: { xs: 0, lg: '24px' },
        },
        '& .MuiPaper-root': {
          backgroundColor: 'linkWater2',
          maxWidth: 'unset',
          width: '1440px',
          maxHeight: '100%',
          margin: 0,
          height: '100vh',
        },
      }}
    >
      {isLoading && (
        <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }} />
      )}
      {children}
    </StyledDialog>
  );
};
