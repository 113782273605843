import { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { CustomErrorMessage } from 'app/components/CustomErrorMessage';

interface OwnProps {
  inputStyles: object;
  minName: string;
  maxName: string;
  control: Control;
  errors: any;
  isEdit: boolean;
  field?: any;
}

export const MinMaxGuestsRange = ({
  inputStyles,
  control,
  errors,
  minName,
  maxName,
  isEdit,
  field,
}: OwnProps) => {
  const { trigger, watch, clearErrors } = useFormContext();
  const minGuests = watch(minName);
  const maxGuests = watch(maxName);

  useEffect(() => {
    if (Number(minGuests) >= Number(maxGuests)) {
      (async () => {
        await trigger([minName, maxName]);
      })();
    } else {
      clearErrors([minName, maxName]);
    }
  }, [minGuests, maxGuests]);

  if (!isEdit) {
    return (
      <Box>
        <Typography>
          {field.min_guests} - {field.max_guests}
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid xs item>
        <Controller
          control={control}
          name={minName}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              type="number"
              inputProps={{ min: '1', max: '998', step: '1' }}
              variant="outlined"
              sx={inputStyles}
            />
          )}
        />

        <CustomErrorMessage errors={errors} name={minName} />
      </Grid>

      <Grid xs={'auto'} item display={'flex'} alignItems={'center'}>
        -
      </Grid>

      <Grid xs item>
        <Controller
          control={control}
          name={maxName}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              type="number"
              inputProps={{ min: '1', max: '999', step: '1' }}
              variant="outlined"
              sx={inputStyles}
            />
          )}
        />

        <CustomErrorMessage errors={errors} name={maxName} />
      </Grid>
    </Grid>
  );
};
