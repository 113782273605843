import { ReactNode } from 'react';
import { Box } from '@mui/material';
import { BookingLeftBar } from 'app/components/BookingModal/_components/BookingLeftBar';
import { BookingBody } from 'app/components/BookingModal/_components/BookingBody';
import { BookingTopBar } from 'app/components/BookingModal/_components/BookingTopBar';
import { BookingBodyWrapper } from 'app/components/BookingModal/_components/BookingBodyWrapper';
import { useTheme } from '@mui/material/styles';
import { useScreenSize } from 'hooks/useScreenSize';

interface OwnProps {
  children: ReactNode;
  onClose: () => void;
}

export const BookingContentWrapper = ({ children, onClose }: OwnProps) => {
  const theme = useTheme();

  const { isMax900 } = useScreenSize();

  return (
    <BookingBodyWrapper>
      <BookingLeftBar />
      <BookingBody>
        <BookingTopBar onClose={onClose} />
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            gap: theme.customSpacing.bookingModal,
            flexDirection: isMax900 ? 'column' : 'row',
            zIndex: 0,
          }}
        >
          {children}
        </Box>
      </BookingBody>
    </BookingBodyWrapper>
  );
};
