import paths from 'app/router/paths';
import { lazy } from 'react';
import {
  getAdminSendMailLogsIndexCall,
  getAdminSendMailLogsShowCall,
} from 'api/app/admin/logs/email';
import { LoaderFunctionArgs } from 'react-router-dom';
import { queryStringToObject } from 'utils/http/queryStringToObject';
import { getAdminErrorLogsCall } from 'api/app/admin/logs/error';
import { getAdminRestaurantsIndexCall } from 'api/app/admin/restaurant';

const RestaurantListPage = lazy(() => import('app/pages/Admin/Restaurant/RestaurantList'));

const adminRestaurants = [
  {
    path: paths.admin.restaurant.index,
    element: <RestaurantListPage />,
    loader: (loader: LoaderFunctionArgs) => {
      const searchQuery = queryStringToObject(loader.request.url);

      return getAdminRestaurantsIndexCall(searchQuery);
    },
  },
];

export default adminRestaurants;
