import adminEmailSettings from 'app/router/routes/admin/adminEmailSettings';
import paths from 'app/router/paths';
import { lazy } from 'react';
import adminLogs from 'app/router/routes/admin/adminLogs';
import adminRestaurants from 'app/router/routes/admin/adminRestaurants';
import adminUsers from 'app/router/routes/admin/adminUsers';
import adminAccounts from 'app/router/routes/admin/adminAccounts';
import adminReservations from 'app/router/routes/admin/adminReservations';

const AdminDashboardPage = lazy(() => import('app/pages/Admin/Dashboard'));

const admin = [
  {
    path: paths.admin.dashboard,
    element: <AdminDashboardPage />,
  },
  ...adminEmailSettings,
  ...adminLogs,
  ...adminRestaurants,
  ...adminUsers,
  ...adminAccounts,
  ...adminReservations,
];

export default admin;
