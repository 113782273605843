import { Alert, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { SideModal } from 'app/components/Modals/SideModal';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { MinMaxGuestsRange } from 'app/pages/RestaurantSettings/TimeManagement/_components/MinMaxGuestsRange';
import {
  timeManagementFieldNames,
  twoHoursInSeconds,
} from 'app/pages/RestaurantSettings/TimeManagement/_config';
import { BookingLengthRange } from 'app/pages/RestaurantSettings/TimeManagement/_components/BookingLengthRange';
import { useAppSelector } from 'redux/hooks';
import { CustomLabel } from 'app/components/CustomLabel';
import { doesRangesOverlap } from 'app/pages/RestaurantSettings/TimeManagement/_utils';
import { CustomErrorMessage } from 'app/components/CustomErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from 'app/pages/RestaurantSettings/TimeManagement/_components/TimeRangeModal/_config/validation';

type FormData = {
  min_guests: string;
  max_guests: string;
  min_length: string;
  max_length: string;
  min_phone_length: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  index?: number;
};

const TimeRangeModal = ({ isOpen, onClose, index }: Props) => {
  const { t } = useTranslation();
  const firstRender = useRef(true);
  const [guestRangeOverlaps, setGuestRangeOverlaps] = useState(false);
  const { reservation_durations } = useAppSelector((state) => state.app.config);
  const options = reservation_durations?.map((option) => ({
    ...option,
    value: Number(option.value),
  }));

  const {
    setValue: setParentFormValue,
    control: parentFormControl,
    watch: parentFormWatch,
  } = useFormContext();

  const { update: parentFormArrayUpdate } = useFieldArray({
    control: parentFormControl,
    name: timeManagementFieldNames.ranges,
  });

  const ranges = parentFormWatch(timeManagementFieldNames.ranges);

  const getFormInitialValues = () => {
    const maxGuestInRange = ranges?.reduce(
      function (prev: FormData, current: FormData) {
        return prev && prev.max_guests > current.max_guests ? prev : current;
      },
      { max_guests: 0 },
    );

    const formDefaultValues = {
      [timeManagementFieldNames.min_guests]: (maxGuestInRange.max_guests || 0) + 1,
      [timeManagementFieldNames.max_guests]: (maxGuestInRange.max_guests || 0) + 2,
      [timeManagementFieldNames.min_length]: '',
      [timeManagementFieldNames.max_length]: twoHoursInSeconds,
      [timeManagementFieldNames.min_phone_length]: '',
    };

    if (index === undefined) {
      return formDefaultValues;
    }

    const range = ranges[index];

    return range === undefined ? formDefaultValues : range;
  };

  const methods = useForm<FormData>({
    defaultValues: getFormInitialValues(),
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const defaultLengthFieldValue = watch(timeManagementFieldNames.max_length);
  const minGuestsFieldValue = watch(timeManagementFieldNames.min_guests);
  const maxGuestsFieldValue = watch(timeManagementFieldNames.max_guests);

  useEffect(() => {
    if (!isOpen) {
      firstRender.current = true;
    }

    if (!isOpen && index === undefined) {
      reset(getFormInitialValues());
    }
  }, [isOpen]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;

      return;
    }

    setValue(timeManagementFieldNames.min_length, '');
    setValue(timeManagementFieldNames.min_phone_length, '');
  }, [defaultLengthFieldValue]);

  useEffect(() => {
    const overlaps = doesRangesOverlap(
      ranges.filter((_: FormData, rangeIndex: number) => rangeIndex !== index),
      getValues(),
    );

    setGuestRangeOverlaps(overlaps);
  }, [minGuestsFieldValue, maxGuestsFieldValue]);

  const handleFormSubmit = async (data: FormData) => {
    if (index !== undefined) {
      parentFormArrayUpdate(index, data);
    } else {
      setParentFormValue(timeManagementFieldNames.ranges, [...ranges, data], { shouldDirty: true });
    }

    onClose();
  };

  return (
    <>
      <SideModal
        handleSubmit={handleSubmit}
        onSubmit={handleFormSubmit}
        methods={methods}
        open={isOpen}
        onClose={onClose}
        title={index !== undefined ? t('editRange') : t('addRange')}
        saveDisabled={guestRangeOverlaps}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomLabel label={t('timeManagementGuests')} />

            <MinMaxGuestsRange
              isEdit={true}
              minName={timeManagementFieldNames.min_guests}
              maxName={timeManagementFieldNames.max_guests}
              // @ts-ignore
              control={control}
              errors={errors}
              inputStyles={{
                '& .MuiInputBase-input': {
                  paddingTop: '10px',
                  paddingBottom: '10px',
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomLabel label={t('timeManagementDefaultBookingLength')} />
            <BookingLengthRange
              isEdit={true}
              fieldName={timeManagementFieldNames.max_length}
              options={options}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomLabel label={t('timeManagementMinPhoneBookingLength')} />
            <BookingLengthRange
              isEdit={true}
              fieldName={timeManagementFieldNames.min_phone_length}
              options={options}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomLabel label={t('timeManagementMinWidgetBookingLength')} />
            <BookingLengthRange
              isEdit={true}
              fieldName={timeManagementFieldNames.min_length}
              options={options}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomErrorMessage errors={errors} name={'ranges'} />

            {guestRangeOverlaps && (
              <Alert severity={'error'}>{t('guestAreOverlappingMessage')}</Alert>
            )}
          </Grid>
        </Grid>
      </SideModal>
    </>
  );
};

export default TimeRangeModal;
