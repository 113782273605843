import { useState, useEffect, useCallback, MutableRefObject } from 'react';
import { BookingModalSection } from 'app/components/BookingModal/_components/BookingModalSection';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Heading } from 'app/components/Heading';
import { SearchAutocomplete } from 'app/components/FormElements/SearchAutocomplete';
import { MuiPhoneInput } from 'app/components/FormElements/MuiPhoneInput';
import { ControlledInput } from 'app/components/FormElements/ControlledInput';
import { ReactComponent as UserIcon } from 'images/icons/ic-user-name.svg';
import { ReactComponent as MailIcon } from 'images/icons/ic-mail-outlined.svg';
import { ReactComponent as CompanyIcon } from 'images/icons/ic-building-outlined.svg';
import { TagSelector } from 'app/components/FormElements/TagSelector';
import { ReservationClientTags } from 'types/reservation';
import { additionalInfo } from 'app/components/BookingModal/_components/GuestSection/_config';
import { bookingFormFieldNames } from 'app/components/BookingModal/_config';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { debounce } from 'lodash';
import { searchClientCall } from 'api/app/restaurant/reservation/search';
import { snakeToCamelCase } from 'utils/str/snakeToCamelCase';
import { useStatus } from 'hooks/useStatus';
import { ReactComponent as GuestTagIcon } from 'images/icons/Badges/ic-tag.svg';
import { MuiTextarea } from 'app/components/FormElements/MuiTextarea';
import { joinWithSeparator } from 'utils/str/joinWithSeparator';
import { useFormContext } from 'react-hook-form';
import { GuestHistory } from 'app/components/BookingModal/_components/GuestSection/_components/GuestHistory';
import { bookingConditions, selectBookingClient } from 'redux/selectors/booking';
import { Client, ClientAdditionalInfo } from 'types/app/clients';
import { fetchBookingClient } from 'redux/slices/bookingSlice';
import { TagButton } from 'app/components/FormElements/TagButton';

interface OwnProps {
  guestNameRef: MutableRefObject<any>;
  guestPhoneRef: MutableRefObject<any>;
}

interface IOption {
  label: string;
  value: Client;
}

export const GuestSection = ({ guestNameRef, guestPhoneRef }: OwnProps) => {
  const client = useAppSelector(selectBookingClient);
  const defaultCountry = useAppSelector((state) => state.app.settings.restaurant.address?.country);
  const { showGuestDetails } = useAppSelector(bookingConditions);

  const dispatch = useAppDispatch();
  const { client_tags } = useAppSelector((state) => state.app.config);
  const { isReadOnly } = useStatus();
  const { watch } = useFormContext();

  const clientTagsValues = watch(bookingFormFieldNames.client.tags);

  const clientAdditionalInfo = client?.additional_info;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const generateVisibleInputsState = useCallback(
    (additionalInfo: ClientAdditionalInfo | undefined) => ({
      [bookingFormFieldNames.client.additional_info.birthday]: !!additionalInfo?.birthday,
      [bookingFormFieldNames.client.additional_info.family_member]: !!additionalInfo?.family_member,
      [bookingFormFieldNames.client.additional_info.favourites]: !!additionalInfo?.favourites,
      [bookingFormFieldNames.client.additional_info.allergies]: !!additionalInfo?.allergies,
    }),
    [],
  );

  const [visibleInputs, setVisibleInputs] = useState(() =>
    generateVisibleInputsState(clientAdditionalInfo),
  );

  const { t } = useTranslation();

  useEffect(() => {
    setVisibleInputs(generateVisibleInputsState(clientAdditionalInfo));
  }, [clientAdditionalInfo]);

  const toggleInputVisibility = (name: any) => {
    setVisibleInputs((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const searchClients = useCallback(
    debounce(async (query) => {
      if (query.length < 2) {
        setOptions([]);
        setLoading(false);
        return;
      }
      setLoading(true);
      try {
        const response = await searchClientCall({ query, limit: 20 });
        setOptions(
          response.map((client) => ({
            label: joinWithSeparator([client?.name, client?.phone, client?.email, client?.company]),
            value: client,
          })),
        );
      } catch (error) {
        setOptions([]);
      }
      setLoading(false);
    }, 500),
    [],
  );

  useEffect(() => {
    if (searchValue) {
      searchClients(searchValue);
    }
  }, [searchValue]);

  const handleClientSelect = (client: Client) => {
    dispatch(fetchBookingClient(client.id));
  };

  const statuses = [
    ReservationClientTags.Vip,
    ReservationClientTags.BigSpender,
    ReservationClientTags.BlackListed,
  ]
    .filter((tag) => clientTagsValues?.includes(tag))
    .map((tag) => ({
      name: t(snakeToCamelCase(tag)),
      status: tag.toLowerCase(),
    }));

  if (client?.stats?.feedback.avg) {
    statuses.unshift({
      name: client.stats.feedback.avg.toString(),
      status: 'rating',
    });
  }

  return (
    <BookingModalSection
      name={t('bookingModalGuestTitle')}
      statuses={statuses}
      additionalText={
        !!client?.stats?.reservation.visited && `${client?.stats?.reservation.visited} visit`
      }
      sectionDisabled={!showGuestDetails}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <SearchAutocomplete
            value={searchValue}
            options={options}
            onOptionSelected={handleClientSelect}
            onInputChange={(data) => setSearchValue(data)}
            isLoading={loading}
            disabled={isReadOnly}
          />
          <ControlledInput
            name={bookingFormFieldNames.client.name}
            placeholder={t('guestName')}
            icon={<UserIcon />}
            inputRef={guestNameRef}
            disabled={isReadOnly}
          />
          <MuiPhoneInput
            name={bookingFormFieldNames.client.phone}
            inputRef={guestPhoneRef}
            defaultCountry={defaultCountry}
            disabled={isReadOnly}
          />
          <ControlledInput
            name={bookingFormFieldNames.client.email}
            type="email"
            placeholder={t('email')}
            icon={<MailIcon />}
            disabled={isReadOnly}
          />
          <ControlledInput
            name={bookingFormFieldNames.client.company}
            placeholder={t('company')}
            icon={<CompanyIcon />}
            disabled={isReadOnly}
          />
          <MuiTextarea
            name={bookingFormFieldNames.client.additional_info.description}
            placeholder={t('guestNotePlaceholder')}
            icon={
              <Box sx={{ width: '16px', height: '16px' }}>
                <GuestTagIcon />
              </Box>
            }
          />
        </Box>

        {!!clientTagsValues && (
          <TagSelector
            name={bookingFormFieldNames.client.tags}
            label={t('clientsInformation')}
            tags={client_tags}
            tagsWrapperSx={{
              maxWidth: '536px',
            }}
            rolledWrapperSx={{
              paddingBottom: 0,
            }}
          />
        )}
        <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
          <Heading>{t('additionalInformation')}</Heading>
          <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
            {additionalInfo.map((info) => (
              <TagButton
                key={info.name}
                color="blueDodger"
                label={t(info.label)}
                handleTagClick={() => toggleInputVisibility(info.name)}
                icon={false}
                isSelected={visibleInputs[info.name]}
                value={info.name}
              />
            ))}
          </Box>
          {additionalInfo.map((info) => {
            if (!visibleInputs[info.name]) {
              return null;
            }

            return (
              <ControlledInput
                icon={info.icon}
                key={info.name}
                name={info.name}
                placeholder={t(info.label)}
                disabled={isReadOnly}
              />
            );
          })}
        </Box>
        <GuestHistory />
      </Box>
    </BookingModalSection>
  );
};
