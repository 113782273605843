import { NewBookingModal } from 'app/components/BookingModal';
import NotesDrawer from 'app/components/Notes/NotesDrawer';

export const GlobalModals = () => {
  return (
    <>
      <NewBookingModal />
      <NotesDrawer />
    </>
  );
};
