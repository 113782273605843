import paths from 'app/router/paths';
import { lazy } from 'react';

import { LoaderFunctionArgs } from 'react-router-dom';
import { queryStringToObject } from 'utils/http/queryStringToObject';

import { getAdminUsersIndexCall } from 'api/app/admin/user';

const UserListPage = lazy(() => import('app/pages/Admin/User/UserList'));

const adminUsers = [
  {
    path: paths.admin.user.index,
    element: <UserListPage />,
    loader: (loader: LoaderFunctionArgs) => {
      const searchQuery = queryStringToObject(loader.request.url);

      return getAdminUsersIndexCall(searchQuery);
    },
  },
];

export default adminUsers;
