import { Box } from '@mui/material';
import { Heading } from 'app/components/Heading';
import { useTranslation } from 'react-i18next';
import { bookingFormFieldNames } from 'app/components/BookingModal/_config';
import { MutableRefObject, useMemo } from 'react';
import { useScreenSize } from 'hooks/useScreenSize';
import { GuestsGrid } from 'app/components/BookingModal/_components/ReservationSection/_components/GuestsGrid';

interface OwnProps {
  guestsRef: MutableRefObject<any>;
}

export const NumbersOfGuests = ({ guestsRef }: OwnProps) => {
  const { t } = useTranslation();
  const name = bookingFormFieldNames.guests;
  const { isBetween901And1340, isBetween561And620, isBetween521And560, isLessThan520 } =
    useScreenSize();

  const numberOfButtons = useMemo(() => {
    switch (true) {
      case isLessThan520:
      case isBetween521And560:
      case isBetween561And620:
      case isBetween901And1340:
        return 10;
      default:
        return 14;
    }
  }, [isLessThan520, isBetween521And560, isBetween561And620, isBetween901And1340]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Heading>{t('numberOfGuests')}</Heading>
      <GuestsGrid
        name={name}
        start={1}
        length={numberOfButtons}
        columnCount={numberOfButtons / 2}
        guestsRef={guestsRef}
      />
    </Box>
  );
};
