import { Dispatch } from 'redux';
import { getUserSettingsCall } from 'api/auth';
import { UserSettingsLoadedAction } from 'types/auth';

const authActions = {
  getUserSettings() {
    return (dispatch: Dispatch) => {
      return getUserSettingsCall().then((userSettings) => {
        dispatch({
          type: 'Auth.UserSettingsLoaded',
          payload: { userSettings },
        } as UserSettingsLoadedAction);
      });
    };
  },
};

export default authActions;
