import { Box } from '@mui/material';
import { range } from 'lodash';
import { StyledGuestButton } from 'app/components/BookingModal/_components/ReservationSection/_components/StyledGuestButton';
import React, { MutableRefObject } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { CustomNumberOfGuests } from 'app/components/BookingModal/_components/ReservationSection/_components/CustomNumberOfGuests';
import { CustomErrorMessage } from 'app/components/CustomErrorMessage';
import { useStatus } from 'hooks/useStatus';

type Props = {
  name: string;
  columnCount: number;
  start: number;
  length: number;
  step?: number;
  guestsRef?: MutableRefObject<any>;
  inputField?: boolean;
  onGuestsChange?: (value: number) => number;
};

export const GuestsGrid = ({
  name,
  columnCount,
  start,
  length,
  guestsRef,
  step = 1,
  inputField = true,
  onGuestsChange,
}: Props) => {
  const { control } = useFormContext();
  const { isReadOnly } = useStatus();

  const {
    field: { onChange, value },
    formState: { errors },
  } = useController({
    name,
    control,
    defaultValue: 0,
  });

  const handleGuestChange = (value: number) => {
    if (onGuestsChange) {
      onChange(onGuestsChange(value));
    } else {
      onChange(value);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gap: '8px',
          width: '100%',
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
        }}
      >
        <>
          {range(start, length, step).map((guestCount, index) => (
            <StyledGuestButton
              key={index}
              isSelected={guestCount === value}
              isReadOnly={isReadOnly}
              disabled={isReadOnly}
              onClick={() => handleGuestChange(guestCount)}
            >
              {guestCount}
            </StyledGuestButton>
          ))}
          {inputField && (
            <CustomNumberOfGuests
              guestsRef={guestsRef}
              isReadOnly={isReadOnly}
              name={name}
              onChange={onChange}
            />
          )}
        </>
      </Box>
      <CustomErrorMessage errors={errors} name={name} />
    </>
  );
};
