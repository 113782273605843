import { useState, useEffect, MouseEvent } from 'react';
import { Box, Menu, MenuItem, Typography, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useController, useFormContext } from 'react-hook-form';
import { handleCountryFlag, FlagCode } from 'utils/locale/handleCountryFlag';
import { Language } from 'enums/language';

interface OwnProps {
  languages: FlagCode[];
  name: string;
  isGridLayout?: boolean;
}

export const CountrySelect = ({ languages, name, isGridLayout = false }: OwnProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const { control, setValue } = useFormContext();
  const { field } = useController({
    name,
    control,
  });
  const [currentLang, setCurrentLang] = useState(field.value || Language.EN);

  useEffect(() => {
    setCurrentLang(field.value);
  }, [field.value]);

  if (!languages || (languages && languages.length === 0)) {
    return null;
  }

  const formattedLanguages = languages.map((lang) => ({
    code: lang,
    label: lang.toString().toUpperCase(),
  }));

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (code: string | undefined) => {
    setCurrentLang(code);
    setValue(name, code);
  };

  const handleMenuItemClick = (code: string | undefined) => {
    changeLanguage(code?.toLowerCase());
    handleClose();
  };

  const getColumnSize = (languagesCount: number) => {
    if (languagesCount === 2) return 12;
    if (languagesCount >= 3) return 4;
    return 6;
  };

  const columnSize = getColumnSize(formattedLanguages.length);

  const showCountrySelect = formattedLanguages.length > 1;
  const effectiveCurrentLang = currentLang === 'en' ? 'gb' : currentLang;

  const optionsToRender = formattedLanguages?.map(({ label, code }, index) => {
    const isCurrentLang = currentLang === code;
    return (
      <Grid item xs={formattedLanguages.length === 2 ? 12 : 6} sm={columnSize} key={index}>
        <MenuItem
          value={code}
          onClick={() => handleMenuItemClick(code)}
          sx={{
            width: '100%',
            borderRadius: theme.borderRadius.small,
            display: 'flex',
            justifyContent: 'flex-start',
            padding: '0.5rem',
            backgroundColor: isCurrentLang ? 'secondary.main' : 'transparent',
            '&:hover': {
              backgroundColor: isCurrentLang ? 'secondary.main' : 'titanWhite',
            },
          }}
        >
          <Box sx={{ display: 'flex', gap: '0.75rem', alignItems: 'center' }}>
            {handleCountryFlag(code)}
            <Typography
              variant="body1"
              sx={{
                fontSize: '0.875rem',
                fontWeight: 600,
                maxWidth: '5rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: isCurrentLang ? 'white' : 'navyBlue',
              }}
            >
              {t(`${label}-language`)}
            </Typography>
          </Box>
        </MenuItem>
      </Grid>
    );
  });

  if (isGridLayout && showCountrySelect) {
    return (
      <Grid container spacing="0.5rem">
        {optionsToRender}
      </Grid>
    );
  }

  return (
    <>
      {showCountrySelect && (
        <>
          <Button
            id="country"
            aria-controls={open ? 'country-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            disableRipple={true}
            startIcon={handleCountryFlag(effectiveCurrentLang)}
            sx={{
              height: 'fit-content',
              minWidth: 'fit-content',
              borderRadius: theme.borderRadius.small,
              position: 'relative',
              padding: 0,
              textAlign: 'center',
              fontWeight: 600,
              color: 'navyBlue',
              fontSize: '1rem',
              '& .MuiButton-startIcon': {
                marginLeft: 0,
              },
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {currentLang && t(`${currentLang.toUpperCase()}-language`)}
          </Button>
          <Menu
            id="country-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            MenuListProps={{
              'aria-labelledby': 'country',
            }}
            PaperProps={{
              style: {
                width: '100%',
                maxWidth: formattedLanguages.length === 2 ? '11rem' : '28.5rem',
                boxShadow: '0px 8px 24px 0px rgba(0, 14, 92, 0.12)',
                borderRadius: theme.borderRadius.small,
                padding: '0.75rem 1.25rem',
              },
            }}
          >
            <Grid container spacing="0.5rem">
              {optionsToRender}
            </Grid>
          </Menu>
        </>
      )}
    </>
  );
};
