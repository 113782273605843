import { initialState } from 'redux/reducers/index';
import {
  ReservationsAction,
  ReservationsActionTypes,
  ReservationsState,
} from 'types/app/reservations';
import { inRange, unionBy, find, map } from 'lodash';

export const reservationsReducer = (
  state: ReservationsState = initialState.app.reservations,
  action: ReservationsAction,
): ReservationsState => {
  switch (action.type) {
    case ReservationsActionTypes.CreateReservation:
      return [...state, action.payload];

    case ReservationsActionTypes.UpdateReservation:
      return map(state, (reservation) =>
        reservation.id === action.payload.id ? action.payload : reservation,
      );

    case ReservationsActionTypes.UpdateReservationTime:
      return [
        ...state.map((reservation) => {
          if (reservation.id === action.payload.id) {
            return {
              ...reservation,
              start_time: action.payload.start,
              end_time: action.payload.end,
              tables: action.payload.tables,
            };
          }

          return reservation;
        }),
      ];

    case ReservationsActionTypes.UpdateBaseReservations:
      return unionBy(state, action.payload, 'id');

    case ReservationsActionTypes.UpdateFullReservations:
      const newState = state.filter((reservation) => {
        return !inRange(reservation.start_time, action.payload.time.start, action.payload.time.end);
      });

      return unionBy(action.payload.reservations, newState, 'id');
    case ReservationsActionTypes.DeleteReservation:
      return state.filter((reservation) => reservation.id !== action.payload);

    case ReservationsActionTypes.SetReservationLoading: {
      const reservation = find(state, { id: action.payload.reservationId });
      if (reservation) {
        const updatedReservation = { ...reservation, isLoading: action.payload.loadingStatus };
        return map(state, (res) =>
          res.id === action.payload.reservationId ? updatedReservation : res,
        );
      }
      return state;
    }
    default:
      return state;
  }
};
