import { useTranslation } from 'react-i18next';
import { Heading } from 'app/components/Heading';
import { CustomCard } from 'app/components/BookingModal/_components/CustomCard';
import { Divider, Grid, LinearProgress } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import { StatisticItem } from 'app/components/BookingModal/_components/GuestSection/_components/GuestHistory/_components/StatisticItem';
import { selectBookingClientStats } from 'redux/selectors/client';

export const GuestHistory = () => {
  const { t } = useTranslation();
  const { client } = useAppSelector((state) => state.booking);
  const stats = useAppSelector(selectBookingClientStats);

  if (!client?.id) {
    return null;
  }

  if (!stats) {
    return <LinearProgress />;
  }

  return (
    <CustomCard>
      <Heading>{t('guestStats')}</Heading>
      <Grid container spacing={2}>
        {Object.entries(stats.reservation).map(([key, value]) => (
          <StatisticItem key={key} label={key} value={value} />
        ))}
      </Grid>
      {!!stats?.feedback?.total && (
        <>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={2}>
            {Object.entries(stats.feedback).map(([key, value]) => (
              <StatisticItem key={key} label={key} value={value} />
            ))}
          </Grid>
        </>
      )}
    </CustomCard>
  );
};
