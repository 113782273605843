import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { ISearchReservationsData, ISearchedReservations } from 'types/app/app';
import { Clients } from 'types/app/clients';

interface ISearchClientData {
  query: string;
  limit: number;
}

export const searchClientCall = async (data: ISearchClientData): Promise<Clients> => {
  return await asyncRequest({
    method: 'POST',
    data,
    baseURL: baseUrl,
    url: ENDPOINT_URLS.RESTAURANT.CLIENT_SEARCH,
  });
};

export const searchReservationsCall = async (
  data: ISearchReservationsData,
): Promise<ISearchedReservations> => {
  return await asyncRequest({
    method: 'POST',
    data,
    baseURL: baseUrl,
    url: ENDPOINT_URLS.RESTAURANT.RESERVATIONS_SEARCH,
  });
};
