import { TFunction } from 'i18next';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import PeopleIcon from '@mui/icons-material/People';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import StorefrontIcon from '@mui/icons-material/Storefront';
import paths from 'app/router/paths';
import { SettingsMenuSection } from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';

const adminSettingsSubNavigationLinks = (t: TFunction): SettingsMenuSection[] => [
  {
    title: t('dashboard'),
    links: [
      {
        icon: <DashboardIcon />,
        to: paths.admin.dashboard,
        name: t('home'),
      },
    ],
  },
  {
    title: t('accounts'),
    links: [
      {
        icon: <AccountTreeIcon />,
        to: paths.admin.account.index,
        name: t('accounts'),
      },
      {
        icon: <WorkspacesIcon />,
        to: paths.admin.account.group,
        name: t('workspace'),
      },
    ],
  },
  {
    title: t('restaurants'),
    links: [
      {
        icon: <RestaurantIcon />,
        to: paths.admin.restaurant.index,
        name: t('restaurants'),
      },
      {
        icon: <StorefrontIcon />,
        to: paths.admin.reservation.index,
        name: t('reservations'),
      },
      {
        icon: <PeopleIcon />,
        to: paths.admin.clients.index,
        name: t('clients'),
      },
    ],
  },
  {
    title: t('users'),
    links: [
      {
        icon: <PeopleIcon />,
        to: paths.admin.user.index,
        name: t('users'),
      },
    ],
  },
  {
    title: t('emails'),
    links: [
      {
        icon: <DocumentScannerIcon />,
        to: paths.admin.email.emailTemplates,
        name: t('emailTemplates'),
      },
    ],
  },
  {
    title: t('logs'),
    links: [
      {
        icon: <MailOutlineIcon />,
        to: paths.admin.logs.emailLogs,
        name: t('sendMailLogs'),
      },
      {
        icon: <BugReportOutlinedIcon />,
        to: paths.admin.logs.errorLogs,
        name: t('errorLogs'),
      },
    ],
  },
];

export default adminSettingsSubNavigationLinks;
