import { Box } from '@mui/material';
import { BookingModalFooter } from 'app/components/BookingModal/_components/BookingModalFooter';
import { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';

interface OwnProps {
  children: ReactNode;
}

export const BookingBody = ({ children }: OwnProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.customSpacing.bookingModal,
        width: '100%',
        height: '100%',
        flex: 1,
      }}
    >
      <Box
        sx={{
          overflowY: 'auto',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Box>
      <BookingModalFooter />
    </Box>
  );
};
