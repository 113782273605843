import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CloseButton } from 'app/components/BookingModal/_components/CloseButton';
import { useScreenSize } from 'hooks/useScreenSize';
import { BookingGroups } from 'app/components/BookingModal/_components/BookingGroups';
import { StatusButtons } from 'app/components/BookingModal/_components/BookingTopBar/_components/StatusButtons';
import { SubStatusButtons } from 'app/components/BookingModal/_components/BookingTopBar/_components/SubStatusButtons';

interface OwnProps {
  onClose: () => void;
}

export const BookingTopBar = ({ onClose }: OwnProps) => {
  const { isMobile } = useScreenSize();
  const theme = useTheme();

  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        zIndex: 4,
        position: 'sticky',
        backgroundColor: 'linkWater2',
      }}
    >
      <Stack
        flexDirection={'row'}
        borderRadius={theme.borderRadius.small}
        width={'100%'}
        marginBottom={theme.customSpacing.bookingModal}
        padding={{ xs: '12px 16px', xl: `12px ${theme.paddings.bookingModal}` }}
        sx={{
          backgroundColor: 'brandWhite',
        }}
      >
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          flex={'wrap'}
          gap={theme.customSpacing.bookingModal}
        >
          {isMobile && <BookingGroups />}
          <SubStatusButtons />
          <StatusButtons />
        </Stack>
        <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'flex-start'} flex={1}>
          <CloseButton onClose={onClose} />
        </Stack>
      </Stack>
    </Box>
  );
};
