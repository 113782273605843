import paths from 'app/router/paths';
import { lazy } from 'react';
import {
  getAdminSendMailLogsIndexCall,
  getAdminSendMailLogsShowCall,
} from 'api/app/admin/logs/email';
import { LoaderFunctionArgs } from 'react-router-dom';
import { queryStringToObject } from 'utils/http/queryStringToObject';
import { getAdminErrorLogsCall } from 'api/app/admin/logs/error';

const EmailLogsIndexPage = lazy(() => import('app/pages/Admin/Logs/EmailLogs/EmailLogsIndex'));
const EmailLogsShowPage = lazy(() => import('app/pages/Admin/Logs/EmailLogs/EmailLogsShow'));
const ErrorLogsPage = lazy(() => import('app/pages/Admin/Logs/ErrorLogs'));

type MailLogShowPageParams = {
  params: {
    logId: number;
  };
};

const adminLogs = [
  {
    path: paths.admin.logs.emailLogs,
    element: <EmailLogsIndexPage />,
    loader: (loader: LoaderFunctionArgs) => {
      const searchQuery = queryStringToObject(loader.request.url);

      return getAdminSendMailLogsIndexCall(searchQuery);
    },
  },
  {
    path: `${paths.admin.logs.emailLogs}/:logId`,
    element: <EmailLogsShowPage />,
    loader: ({ params }: MailLogShowPageParams) => {
      return getAdminSendMailLogsShowCall(params.logId);
    },
  },
  {
    path: paths.admin.logs.errorLogs,
    element: <ErrorLogsPage />,
    loader: (loader: LoaderFunctionArgs) => {
      const searchQuery = queryStringToObject(loader.request.url);

      return getAdminErrorLogsCall(searchQuery);
    },
  },
];

export default adminLogs;
