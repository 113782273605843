import { enqueueSnackbar } from 'notistack';
import { MessageVariants } from 'enums/notifications';
import i18n from 'i18next';

export const handleErrors = (error: any) => {
  const { t } = i18n;

  switch (error?.response.status) {
    case 403:
      enqueueSnackbar(t('accessDeniedErrorMessage'), { variant: MessageVariants.Error });
      break;
    case 422:
      for (const property in error.response.data.errors) {
        enqueueSnackbar(error.response.data.errors[property], { variant: MessageVariants.Error });
      }
      break;
    default:
      enqueueSnackbar(t('genericErrorMessage'), {
        variant: MessageVariants.Error,
      });
  }
};
